import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import * as constants from "../../../common/Constants";
import {ReactSVG} from "react-svg";


// const new_schedule = makeStyles((theme) => ({
//     root: {
//         display: 'block',
//         '& > *': {
//             margin: theme.spacing(1),
//             width: theme.spacing(45),
//             // height: theme.spacing(13),
//         },
//     },
//     new_schedule: {
//         width:'100%',
//         textAlign:'center'
//     }
// }));

export default function InvitationCreateButton(props){
    const {t, i18n} = useTranslation();

    const clickevent = props.onClick;

    const new_schedule = makeStyles((theme) => ({
        root: {
            display: 'block',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(45),
                // height: theme.spacing(13),
            },
        },
        new_schedule: {
            width: 'max-content',
            textAlign: 'center',
            backgroundColor: '#c4c4c4',
            borderRadius: '50%',
            padding: 16,
            marginTop: 20,
        },
        rootWhite: {
            width: '320px',
            height: '290px',
            background: '#ffffff',
            color: '#000000',
            borderRadius: '10px',
            boxShadow: '0px 0px 2px 2px #5e5c9a1a',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px 24px',
        },
        rootWhiteCircle: {
            width: 130,
            height: 130,
            background: '#ffffff',
            color: '#000000',
            borderRadius: '50%',
            boxShadow: '0px 0px 2px 2px #5e5c9a1a',
            display: 'flex',
            justifyContent: 'center',
            margin: '57px 24px',
        },
        plusButtonLeft: {

        },
        plusButtonBig: {
            margin: theme.spacing(1),
            '&:hover, &$focusVisible': {
                backgroundColor: "transparent !important"
            }
        },
    }));

    const classes = new_schedule();
    if(props.type == 'small'){
        return <Button   size="medium" onClick={clickevent} variant="contained" height="50" color="secondary">{t('+ New Invitation')}</Button>
    }
    return(

        <React.Fragment key={'right'}>
            <Grid container spacing={3}>
                <Grid  item>
                    <Paper className={classes.rootWhite} variant='outlined' >
                        <div className={classes.new_schedule} >
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-edit-button');
                                svg.setAttribute('style', 'width:100px');
                            }} src={constants.LocalUrl + '/images/panel/marketing-new.svg'}/>
                        </div>
                        {/*<Typography alignLeft variant="h3" gutterBottom>*/}
                        {/*    {t('New Invitation')}*/}
                        {/*</Typography>*/}
                        {/*<Typography alignLeft variant="subtitle1" gutterBottom>*/}
                        {/*    {t('New Invitation info text')}*/}
                        {/*</Typography>*/}
                        <Button size="medium" onClick={clickevent} variant="contained" height="50" color="secondary" style={{width: "max-content"}}>{t('New Invitation')}</Button>

                    </Paper>
                    <Paper />
                </Grid>
                <Grid  item>
                    <Paper className={classes.rootWhiteCircle} variant='outlined' >
                        {/*<AddIcon className={classes.new_schedule} style={{ fontSize: 80 }} />*/}
                        {/*<Typography alignLeft variant="h3" gutterBottom>*/}
                        {/*    {t('New Invitation')}*/}
                        {/*</Typography>*/}
                        {/*<Typography alignLeft variant="subtitle1" gutterBottom>*/}
                        {/*    {t('New Invitation info text')}*/}
                        {/*</Typography>*/}
                        {/*<Button size="medium" onClick={clickevent} variant="contained" height="50" color="secondary">{t('New Invitation')}</Button>*/}

                        <IconButton className={classes.plusButtonBig} onClick={clickevent}>
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-edit-button');
                                svg.setAttribute('style', 'width:60px');
                            }} src={constants.LocalUrl + '/images/panel/new-plus.svg'}/>
                        </IconButton>

                    </Paper>
                    <Paper />
                </Grid>
            </Grid>
        </React.Fragment>

    )
}