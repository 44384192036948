import React, {useEffect, useState} from 'react'
import axios from "axios";
import Loader from "../../../common/Loader";
import * as constants from "../../../Panel/common/Constants";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Grid} from "@material-ui/core";
import {NavLink, useNavigate} from "react-router-dom";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useTranslation} from "react-i18next";
import SimpleBar from "simplebar-react";

export default function StageOndemandList (props){

    const {t, i18n} = useTranslation();
    const [ondemand,setOndemand] = useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate();

    const gotoUserProfile = (id) => {
        navigate('/profile/'+id);
    };

    const toggleSpeakers = (index) => {
        jQuery('#session_'+index+' .omilites').toggle();
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const useStyles = makeStyles({
        card: {
            maxWidth: '100%',
            marginBottom: '20px',
        },
        media: {
            height: '10vw',
            position: "relative"
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            // transition: theme.transitions.create('transform', {
            //     duration: theme.transitions.duration.shortest,
            // }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
    });
    const classes = useStyles();

    let ti = props.streamcode;

    useEffect(() => {
        const loadPost = () => {
            axios.post(constants.LocalUrl + "/data/get_ondemand_videos").then((response) => {
                setOndemand(response.data);
            });
        }
        // Call the function
        loadPost();
    }, []);

    if (ondemand == null) {
        return <Loader/>
    }

    if (props.currentSession == null && ondemand.sessions) {
        var firstSession = null;
        var sessions = Object.values(ondemand.sessions).map((item) => {return item[ti];}).filter((item)=>{return item.embed_url != null;});
        if (sessions.length > 0) {
            firstSession = sessions[0];
        }
        if (firstSession) {
            props.sessionHandler(firstSession.embed_url)
        }
    }

    return <SimpleBar id='stageOndemandSimpleBar' style={{maxHeight:"40vw", height:"40vw", overflowX: "hidden"}} forceVisible="y" autoHide={false} >
        {/*{
            ondemand.stage[ti].embed_url != null
                ? <div>
                    <h3>Stage video</h3>
                    <img onClick={()=>props.sessionHandler(ondemand.stage[ti].embed_url)} src={ondemand.stage[ti].embed_image} alt="" style={{'max-width': '100%'}}/>
                </div>
                : <></>
        }*/}
        {
            Object.values(ondemand.sessions).map((item) => {return item[ti];}).filter((item)=>{return item.embed_url != null;}).length > 0
            ? <>
                    {Object.values(ondemand.sessions).map((item) => {return item[ti];}).filter((item)=>{return item.embed_url != null;}).map((session, index) => <>
                        <Card id={'session_'+index} className={classes.card}>
                            <Card className={classes.card} style={{margin: '0px'}}>
                                <Grid xs={12} container spacing={0} style={{padding:'0px'}}>
                                    <Grid item xs={12}>
                                        <CardActionArea onClick={()=>props.sessionHandler(session.embed_url)}>
                                        <CardMedia
                                            className={classes.media}
                                            image={session.embed_image}
                                            title={session.title}
                                            style={{'background-size': 'cover'}}
                                        >
                                            <div style={{position: "absolute", color: "white",top: '50%',left: "50%",transform: "translateX(-50%) translateY(-50%)",}}>
                                                <PlayCircleOutlineIcon fontSize={"large"}></PlayCircleOutlineIcon>
                                            </div>
                                        </CardMedia>
                                        </CardActionArea>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div style={{padding: '4px 10px'}} onClick={()=>props.sessionHandler(session.embed_url)}>
                                            <Typography gutterBottom variant="h6" component="h2" style={{fontSize: '1rem', padding: '8px 0'}}>
                                                {session.title}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>

                                        {
                                            (session != null && session.omilites && session.omilites.length > 0) || session.subtitle
                                                ?
                                                <CardActionArea style={{textAlign:"center",height:"100%"}} onClick={()=>toggleSpeakers(index)}>
                                                    <div style={{
                                                    }}><ExpandMoreIcon style={{fontSize:"1.9rem"}} /></div>
                                                </CardActionArea>
                                                :
                                                <div style={{display: 'none'}}></div>
                                        }
                                    </Grid>
                                </Grid>
                            </Card>

                            {
                                (session != null && session.omilites && session.omilites.length > 0) || session.subtitle
                                    ? <div className={"omilites"} style={{padding: '10px', display: 'none'}}>
                                        <Typography variant="body1" color="textSecondary" component="p" style={{marginBottom:"20px",fontSize:"15px"}}>
                                            <span dangerouslySetInnerHTML={{__html: session.subtitle}}></span>
                                        </Typography>
                                        {session.omilites.length > 0 ? <div className="omilites_content">
                                            <h6 style={{fontSize: '14px', lineHeight: '16px', color: '#000000', fontWeight: 700, paddingBottom: '10px'}}>{t('Speakers')}</h6>
                                            {session.omilites && Object.values(session.omilites).map(user => (
                                                <div>
                                                    <NavLink as="a" to={constants.LocalUrl + "/profile/" + user.id} style={{fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{user.name}</NavLink>
                                                    <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 500, marginBottom: 'auto'}}>{user.jobtitle}</h6>
                                                    <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 700}}>{user.company}</h6>
                                                </div>
                                            ))}
                                        </div> : null }
                                        {/*{session.omilites && Object.values(session.omilites).map(user => (*/}
                                        {/*    <UserCard user={user}>*/}
                                        {/*        <div style={{width: '20%', padding: '17px 0', display: 'flex'}}>*/}
                                        {/*            <button onClick={()=>gotoUserProfile(user.id)} className="btn btn-secondary" type="button" style={{*/}
                                        {/*                borderColor: "#c4c4c4",*/}
                                        {/*                borderRadius: '50%',*/}
                                        {/*                display: 'inline-block',*/}
                                        {/*                marginLeft: '20px',*/}
                                        {/*                width: '46px',*/}
                                        {/*                height: '46px',*/}
                                        {/*                backgroundColor: "#c4c4c4"*/}
                                        {/*            }}><ContactsIcon/></button>*/}
                                        {/*        </div>*/}
                                        {/*    </UserCard>*/}
                                        {/*))}*/}
                                    </div>
                                    : <div style={{display: 'none'}}></div>
                            }
                            {
                                (session != null && session.syntonistes && session.syntonistes.length > 0) || session.subtitle
                                    ? <div className={"omilites"} style={{padding: '10px', display: 'none'}}>
                                        {session.syntonistes.length > 0 ? <div className="omilites_content">
                                            <h6 style={{fontSize: '14px', lineHeight: '16px', color: '#000000', fontWeight: 700, paddingBottom: '10px'}}>{t('Coordinators')}</h6>
                                            {session.syntonistes && Object.values(session.syntonistes).map(user => (
                                                <div>
                                                    <NavLink as="a" to={constants.LocalUrl + "/profile/" + user.id} style={{fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{user.name}</NavLink>
                                                    <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 500, marginBottom: 'auto'}}>{user.jobtitle}</h6>
                                                    <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 700}}>{user.company}</h6>
                                                </div>
                                            ))}
                                        </div> : null }
                                        {/*{session.omilites && Object.values(session.omilites).map(user => (*/}
                                        {/*    <UserCard user={user}>*/}
                                        {/*        <div style={{width: '20%', padding: '17px 0', display: 'flex'}}>*/}
                                        {/*            <button onClick={()=>gotoUserProfile(user.id)} className="btn btn-secondary" type="button" style={{*/}
                                        {/*                borderColor: "#c4c4c4",*/}
                                        {/*                borderRadius: '50%',*/}
                                        {/*                display: 'inline-block',*/}
                                        {/*                marginLeft: '20px',*/}
                                        {/*                width: '46px',*/}
                                        {/*                height: '46px',*/}
                                        {/*                backgroundColor: "#c4c4c4"*/}
                                        {/*            }}><ContactsIcon/></button>*/}
                                        {/*        </div>*/}
                                        {/*    </UserCard>*/}
                                        {/*))}*/}
                                    </div>
                                    : <div style={{display: 'none'}}></div>
                            }
                        </Card>
                    </>)}
                </>
            : <span>{t('no ondemand videos')}</span>
        }

    </SimpleBar>;

}