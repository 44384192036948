import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Divider from "@material-ui/core/Divider";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import {useTranslation} from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, TimePicker, KeyboardDateTimePicker, DateTimePicker} from "@material-ui/pickers";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import {
    datepickerCancelLabelLocales,
    datepickerClearLabelLocales,
    datepickerLocales
} from "../../../../common/Language/DatepickerLocales";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import LoadedScheduleHook from "./LoadedScheduleHook";
import DateTimer from "../../../../common/DateTimer";


export default function LoadedSchedule(props){


    const {t, i18n} = useTranslation();

    const {
        changeLocale,
        schedule,
        scheduleLocale,
        editorSubtitle,
        localeLanguage,
        speakers,
        coordinators,
        handleEndTime,
        handleStartTime,
        handleSyntonistesOrder,
        handleDescription,
        handleSpeakersOrder,
        onSpeakersChange,
        onSyntonistesChange,
        onInputchange,
        onSubmit,hasError,errorMessage } = LoadedScheduleHook(props,'edit');






    // let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'},{id:'local',title:'LiveOn'},{id:'facebook',title:'Facebook'}]
    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]

    if(Object.keys(schedule).length == 0
        // || Object.keys(speakers).length == 0 || Object.keys(coordinators).length == 0 || Object.keys(sponsors).length == 0
        // || sponsors.length == 0 || coordinators.length == 0 || speakers.length == 0
    ){
        return <Loader />
    }

    return (
        <div className="bottom_bar">

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Edit Session')}
            </Typography>
            <Typography alignLeft variant="subtitle1" style={{textAlign:'center'}} gutterBottom>
                <span style={{fontSize:"21px",fontStyle:"italic"}}>{scheduleLocale.title}</span>
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <div style={{display:'flex',justifyContent:'center'}}>
            <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

            <Grid item xs={12}>
                <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                    {t('Session Details')}
                </Typography>


                <Divider light />
            </Grid>

            <Grid item xs={12}>
                {/*{console.log("scheduleLocale",scheduleLocale)}*/}
                <CTextField value={scheduleLocale && scheduleLocale.title?scheduleLocale.title:''} handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
            </Grid>

            <Grid item xs={12}>
                <Editor lang={localeLanguage} handler={handleDescription} value={editorSubtitle} label={t("Description")}  name="subtitle"/>
            </Grid>

            <Grid item xs={6}>
                <DateTimer
                    label={t('Start Date')}
                    data={schedule.start_time_picker}
                    handler={handleStartTime}
                    name="start_time"
                    hasError={hasError("start_time")}
                    errorMessage={errorMessage("start_time")}
                    required
                />
            </Grid>

            <Grid item xs={6}>
                <DateTimer
                    label={t('End Date')}
                    data={schedule.end_time_picker}
                    handler={handleEndTime}
                    name="end_time"
                    hasError={hasError("end_time")}
                    errorMessage={errorMessage("end_time")}
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                    {t('Speakers & Coordinators')}
                </Typography>
                <Divider light />
            </Grid>

            {Object.keys(speakers).length > 0?<Grid item xs={6}>
                <AutoCompleteField
                    label={t('Speakers')}
                    placeholder={t("Choose a speaker...")}
                    model={speakers}
                    data={schedule.speakers}
                    onChange={onSpeakersChange}
                    handleItemsOrder={handleSpeakersOrder}
                />

            </Grid>:null}

            {Object.keys(coordinators).length > 0?<Grid item xs={6}>
                <AutoCompleteField
                label={t('Coordinators')}
                placeholder={t("Choose a speaker...")}
                model={coordinators}
                data={schedule.syntonistes}
                onChange={onSyntonistesChange}
                handleItemsOrder={handleSyntonistesOrder}
            /></Grid>:null}


            <PanelBottomBar language={changeLocale} close={props.triggerdrawer(false)}  submit={onSubmit}/>


        </Grid>

        </div>
        </div>
    )
}