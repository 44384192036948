import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PageProvider from "../../../../Page/PageContext";
import * as constants from "../../../common/Constants";
import {InputTextHandler} from "../../../common/InputTextHandler";
import {SetLocaleData} from "../../../../common/SetLocaleData";
import {FormErrors} from "../../../../common/FormErrors";
import Users from "../../../../../api/services/Panel/users"
import Agenda from "../../../../../api/services/Panel/agenda"


const LoadedScheduleHook = (props) => {

    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    let non_translate = ["syntonistes", "speakers", "start_time_picker", "end_time_picker"]
    const {hasError, errorMessage, setFormErrors} = FormErrors();

    const [loading, setLoading] = useState(false);
    const [operationType, setOperationType] = useState(props.type);
    const [schedule, setSchedule] = useState([]);
    const [scheduleLocale, setScheduleLocale] = useState([]);
    const [editorSubtitle, setEditorSubtitle] = useState(null);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [speakers, setSpeakers] = useState([]);
    const [coordinators, setCoordinators] = useState([]);
    const [validationErrors, setValidationErrors] = useState(null);
    const {
        onTextchange
        , handleDraggableListItemInState
        , handleDraggableOrder
        , handleEditor
        , handleDateTimePicker
    }
        = InputTextHandler({state: schedule, setState: setSchedule, lang: localeLanguage});
    const {setLocaleDataFunction} = SetLocaleData(non_translate);
    let correct_end_time = {end_time: [t('set_correct_end_time')]}

    useEffect(() => {
        setFormErrors(validationErrors);
    }, [validationErrors])

    const setLocaleData = (data) => {
        setLocaleDataFunction(data ? data : schedule, setScheduleLocale, localeLanguage, setEditorSubtitle);
    }

    useEffect(() => {
        setLocaleData();
    }, [schedule, localeLanguage])

    useEffect(() => {
        if (Object.keys(schedule).length > 0) {
            setEditorSubtitle(schedule.subtitle[localeLanguage]);
        }
    }, [localeLanguage])

    const changeLocale = (lang) => {
        setLocaleLanguage(lang);
    }

    useEffect(() => {

        if (props.props) {
            loadSchedule();
        } else {
            let stime = new Date(settings.EVENT_DATE_TIMESTAMP);

            var obj_settings = {
                name: "newschedule",
                start_date: stime,
                end_date: stime,
                // start_time:stime.getTime(),
                // end_time:stime.getTime(),
                start_time_picker: stime.getTime(),
                end_time_picker: stime.getTime(),
            };
            setSchedule(obj_settings);
        }

        Users.speakers(localeLanguage).then((response) => {
            setSpeakers(response)
        });

        Users.coordinators(localeLanguage).then((response) => {
            setCoordinators(response)
        });


    }, []);

    const loadSchedule = async () => {

        setLoading(true);


        const wert = await axios.post(constants.LocalUrl + '/data/schedule_full_data', {
            id: props.props,
            type: "panel"
        }).then(response => {

            if (response.data[0].start_time) {

                let start_time = new Date(response.data[0].start_time * 1000)
                let end_time = new Date(response.data[0].end_time * 1000)

                response.data[0].start_time_picker = start_time;
                response.data[0].end_time_picker = end_time;
                response.data[0].start_time = start_time.getTime();
                response.data[0].end_time = end_time.getTime();
                console.log("response setLocaleData", response.data[0]);
                setSchedule(response.data[0]);
                setLocaleData(response.data[0])
                // console.log("setEditorSubtitle",response.data[0].subtitle[i18n.language]);
                setEditorSubtitle(response.data[0].subtitle[i18n.language]);
            }

        });

    }


    const onSubmit = () => {
        if (props.props) {
            const loadItems = async () => {

                // console.log("onSubmit",scheduleLocale);
                await axios.post(
                    constants.LocalUrl + '/data/update_schedule', {
                        ...scheduleLocale,
                        lang: localeLanguage
                    }).then(response => {

                    if (response.data.status == 'error') {
                        console.log("data.errors", response.data.data.errors);
                        setValidationErrors(response.data.data.errors);
                    } else if (response.data.status == 'ok') {

                        let index_id;
                        const i = props.posts.map((single_post, index) => {
                                if (single_post.id === response.data.data.id) {
                                    index_id = index;
                                    return true;
                                }
                            }
                        );

                        let items = [...props.posts];
                        let item = {...items[index_id]};
                        item.title = response.data.data.title;
                        item.startDate = response.data.data.startDate;
                        item.startTime = response.data.data.startTime;
                        items[index_id] = item;

                        props.triggerdataload(items);
                        props.ooo(false);
                    }
                });

            }

            // Call the function
            loadItems();
        } else {
            const loadItems = async () => {

                setLoading(true);

                const response = await axios.post(
                    constants.LocalUrl + '/data/create_schedule', schedule).then(response => {
                    if (response.data.status == 'error') {
                        console.log("data.errors", response.data.data.errors);
                        setValidationErrors(response.data.data.errors);
                    } else if (response.data.status == 'ok') {


                        let items = [...props.posts];
                        let item = {};
                        item.title = response.data.data.title;
                        item.startDate = response.data.data.startDate;
                        item.startTime = response.data.data.startTime;
                        item.id = response.data.data.id;
                        items.push(item)

                        console.log("items", items)
                        props.triggerdataload(items);
                        props.ooo(false);
                    }
                    setLoading(false);
                });

                // After fetching data stored it in posts state.
                // setSchedule(response.data);

            }

            // Call the function
            loadItems();
        }
    }


    // event, state, type, extra (κλειδιά από keys σε τα arrays)
    const onInputchange = (event) => {
        onTextchange(event);
    }

    const handleDescription = (data) => {
        handleEditor(data, 'subtitle');
    }

    const handleSpeakersOrder = (event) => {
        handleDraggableOrder(event, 'speakers');
    }

    const handleSyntonistesOrder = (event) => {
        handleDraggableOrder(event, 'syntonistes');
    }

    const onSyntonistesChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'syntonistes');
    }

    const onSpeakersChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'speakers');
    }

    const handleStartTime = (event) => {

        let start_date_now = new Date(event);
        let end_date_now = new Date(schedule.end_time_picker);

        // console.log("start_date_now",start_date_now,end_date_now,end_date_now.getTime(),event.getTime())

        if (event.getTime() >= end_date_now.getTime()) {
            handleEndTime(event, "start_time");
        }

        // handleDateTimePicker(event,schedule,setSchedule,setScheduleLocale);
        handleDateTimePicker(event, "start_time");
    }

    const handleEndTime = (event, mode = "handler") => {

        let start_date_now = {};
        let end_date_now = {};

        if (mode == "handler") {
            start_date_now = new Date(schedule.start_time_picker);
            end_date_now = new Date(event);
        } else if (mode == "start_time") {
            start_date_now = new Date(event);
            end_date_now = new Date(schedule.end_time_picker);
        }

        // console.log("end_date_now",end_date_now,start_date_locale,event.getTime())
        if (end_date_now.getTime() < start_date_now.getTime()) {
            end_date_now.setHours(start_date_now.getHours(), start_date_now.getMinutes() + 1, 0, 0);
            mode == "handler" ? setFormErrors(correct_end_time) : null;
        }
        // console.log("end_date_noww",end_date_now,start_date_locale,event.getTime())

        handleDateTimePicker(end_date_now, "end_time");
    }

    return {
        settings: settings,
        schedule: schedule,
        scheduleLocale: scheduleLocale,
        editorSubtitle: editorSubtitle,
        localeLanguage: localeLanguage,
        speakers: speakers,
        coordinators: coordinators,
        loading: loading,
        validationErrors: validationErrors,
        handleEndTime,
        changeLocale,
        handleStartTime,
        handleSyntonistesOrder,
        handleDescription,
        handleSpeakersOrder,
        onSpeakersChange,
        onSyntonistesChange,
        onInputchange,
        onSubmit, hasError, errorMessage, setFormErrors
    };

}

export default LoadedScheduleHook;