import React from 'react';
import {useNavigate} from "react-router-dom";
import Button from '@material-ui/core/Button';
import * as constants from "../../../Panel/common/Constants";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";


export default function Welcome(props){

    const {t, i18n} = useTranslation();


    return(
        <div className="wizard_wrapper">
        <div className="wizard_start">
            <img src="/images/panel/wizard_welcome.png" alt="wizard_welcome"/>
            <p style={{color:"#39bb0f",fontWeight:"bold",fontSize:"43px"}} dangerouslySetInnerHTML={{__html: t("wizard_welcome_1")}} />
            <p style={{color:"#464646",fontSize:"25px",fontWeight:500}} dangerouslySetInnerHTML={{__html: t("wizard_welcome_2")}} />

            <Button style={{marginTop:"30px",padding:"14px 35px",fontSize:"18px"}} id="close_button" variant="contained" color="primary" onClick={() => props.closeWindow(false)} component="span">{t("Start_Wizard")}</Button>
        </div>
        </div>
    )
}