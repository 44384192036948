import {useEffect, useState} from "react";

export function SetLocaleData(non_translatable) {

    const [data,setData] = useState()
    let no_translate = non_translatable;

    const setLocaleDataFunction = (data, setScheduleLocale, localeLanguage, setEditorSubtitle) => {
        let object = {...data}
        let localeData = {};
        Object.keys(object).map(value => {

            if (typeof object[value] === 'object' && object[value] !== null && !no_translate.includes(value)) {
            // if (typeof object[value] === 'object' && object[value] !== null && value != "syntonistes" && value != "speakers" && value != "start_time_picker" && value != "end_time_picker") {
                // console.log("a",value,data[value])
                localeData[value] = object[value][localeLanguage] ? object[value][localeLanguage] : " ";
            } else {
                // console.log("b",value,data[value])
                localeData[value] = object[value] ? object[value] : " ";
            }
        })
        // console.log('setLocaleData', data, localeData,object, localeLanguage);
        // useEffect(() => {
        setScheduleLocale(localeData);
        // setEditorSubtitle??setEditorSubtitle(localeData.subtitle);
        // },[data])
        // console.log("localeData",localeData,localeLanguage)

    }

    return {setLocaleDataFunction}
}