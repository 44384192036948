import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Button from '@material-ui/core/Button';
import * as constants from "../../../Panel/common/Constants";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";


export default function Complete(){

    const {t, i18n} = useTranslation();

    const routeChange = () =>{




                window.axios.post(constants.LocalUrl + '/data/update_wizard_state',{value:0}).then((response) => {
                    if(response.data.status == "ok"){
                        // notify();
                        document.location.href="/";
                    }
                })




    }

    return(
        <div className="wizard_wrapper">
            <div className="wizard_success">
                <img src="/images/panel/wizard_success.png" alt="wizard_success"/>
                <p style={{color: "#39BB0F",
                    fontSize: "66px",
                    fontWeight: "bold",
                    marginTop: "30px"}} dangerouslySetInnerHTML={{__html: t("wizard_success_1")}}/>

                <Button style={{padding:"14px 35px",fontSize:"18px"}} id="close_button" variant="contained" color="primary" onClick={routeChange} component="span">{t("Go_to_homepage")}</Button>
            </div>
        </div>
    )
}