import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import * as constants from "./Constants";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import LanguageChangerForPanel from "../../common/Language/LanguageChangerForPanel";
import {useTranslation} from "react-i18next";
import Loader from "../../common/Loader";
import PanelBottomBar from "../../common/PanelBottomBar";

export default function HelpValue (props){

    const {t, i18n} = useTranslation();
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState(false);

    useEffect(() => {

        const loadSponsor = async () => {

            setLoading(true);

            const response = await axios.post(constants.LocalUrl + '/data/get_help_text_data',{id: props.id}).then(response => {

                // After fetching data stored it in posts state.
                setData(response.data);
                // Closed the loading page
                setLoading(false);
            });

        }

        // Call the function
        loadSponsor();

    }, []);

    if(loading === true){
        return <Loader/>
    }

    return (
        <Grid container spacing={12} style={{padding:'20px'}}>
            <Grid item xs={12}>
                <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                    {data.name}
                </Typography>
                <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
                <div  dangerouslySetInnerHTML={{__html:data.description}}/>
                {
                    props.children
                        ?<><br/>{props.children}</>
                        : null
                }
            </Grid>
        </Grid>
    )
}
