import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";


// const new_schedule = makeStyles((theme) => ({
//     root: {
//         display: 'block',
//         '& > *': {
//             margin: theme.spacing(1),
//             width: theme.spacing(45),
//             // height: theme.spacing(13),
//         },
//     },
//     new_schedule: {
//         width:'100%',
//         textAlign:'center'
//     }
// }));

export default function SponsorCreatebutton(props){

    const {t, i18n} = useTranslation();
    const clickevent = props.onClick;

    const new_schedule = makeStyles((theme) => ({
        root: {
            display: 'block',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(45),
                // height: theme.spacing(13),
            },
        },
        new_schedule: {
            width:'100%',
            textAlign:'center'
        }
    }));

    const classess = new_schedule();
    // if(props.type == 'small'){
        return <Button   size="medium" onClick={clickevent} variant="contained" height="50" color="secondary">{t('+ New Sponsor')}</Button>
    // }
    return(

            <React.Fragment key={'right'}>
                <Grid container spacing={3}>
                    <Grid  item xs={8}>
                        <Paper className={classess.root} variant='outlined' >
                            <AddIcon className={classess.new_schedule} style={{ fontSize: 80 }} />
                            <Typography alignLeft variant="h3" gutterBottom>
                                {t('New Sponsor')}
                            </Typography>
                            <Typography alignLeft variant="subtitle1" gutterBottom>
                                subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                            </Typography>
                            <Button   size="medium" onClick={clickevent} variant="contained" height="50" color="secondary">{t('New Sponsor')}</Button>

                        </Paper>
                        <Paper />
                    </Grid>
                </Grid>
            </React.Fragment>

    )
}