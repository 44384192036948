import React, {useRef, useEffect, useState} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import Typography from "@material-ui/core/Typography";

export default function MyEditor(props) {
    const editorRef = useRef(null);

    const handleEditorChange = (newValue, editor) => {
        props.handler(editorRef.current.getContent());
    };

    const elFinderBrowser = (field_name, url, type, win) => {
        tinymce.activeEditor.windowManager.open({
            file: '/panel/filemanager/tinymce4',// use an absolute path!
            title: 'elFinder 2.0',
            width: 900,
            height: 450,
            resizable: 'yes'
    }, {
            setUrl: function (url) {
                win.document.getElementById(field_name).value = url;
            }
        });
        return false;
    }

    return (
        <>
            {props.header == "h5"?<Typography variant="h5" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                {props.label}
                {props.required?' *':''}
            </Typography>:<Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                {props.label}
                {props.required?' *':''}
            </Typography>}
            <Editor
                tinymceScriptSrc="/packages/tinymce/tinymce.min.js"
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={props.value??''}
                onEditorChange={(newValue, editor) => handleEditorChange(newValue, editor)}
                init={{
                    height: props.initial_height??200,
                    menubar: false,
                    entity_encoding: 'raw',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar: 'undo redo | formatselect fontsizeselect | bold italic underline backcolor | strikethrough subscript superscript | ' +
                        'link image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
                    fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    file_browser_callback : elFinderBrowser
                }}
            />
        </>
    );
}
