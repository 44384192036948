import React, {useCallback, useRef} from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {chartjsBackgroundPlugin} from "../Panel/common/Constants";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export default function HorizontalBarChart(props) {

    const {t, i18n} = useTranslation();
    const ref = useRef(null);

    const donwloadImage = useCallback(() => {
        const link = document.createElement("a")
        link.download = "attendance.png"
        link.href = ref.current.toBase64Image()
        link.click()
    },[])

    return (

        <div style={{display:"block",width:"100%",backgroundColor:"#fff"}}>
            <Button style={{float:"right"}} size="large" onClick={donwloadImage} variant="contained" color="primary" component="span">{t('Download')}</Button>
            <Line options={{
                scales: {
                    y: {
                        min: 0,
                        ticks: {
                            // forces step size to be 50 units
                            stepSize: 1
                        }
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: '',
                    },
                    customCanvasBackgroundColor: {
                        color: 'white',
                    }
                },
            }} data={props.data} plugins={[chartjsBackgroundPlugin]} ref={ref}/>
            {/*<Bar ref={ref} options={options} data={data} />*/}
        </div>

    );
}
