import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import {useTranslation} from "react-i18next";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import LoadedScheduleHook from "./LoadedScheduleHook";
import DateTimer from "../../../../common/DateTimer";



export default function NewSchedule(props){

    const {t, i18n} = useTranslation();

    const {
        schedule,
        speakers,
        coordinators,
        handleEndTime,
        handleStartTime,
        handleSyntonistesOrder,
        handleDescription,
        handleSpeakersOrder,
        onSpeakersChange,
        onSyntonistesChange,
        onInputchange,
        onSubmit,hasError,errorMessage } = LoadedScheduleHook(props,'edit');

    // let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'},{id:'local',title:'LiveOn'},{id:'facebook',title:'Facebook'}]
    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]


    return (
        <div className="bottom_bar">

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('New Session')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>


            <div style={{display:'flex',justifyContent:'center'}}>
                <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t('Session Details')}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <CTextField handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Editor handler={handleDescription} label={t("Description")} name="subtitle"/>
                    </Grid>

                    <Grid item xs={6}>
                        <DateTimer
                            label={t('Start Date')}
                            data={schedule.start_time_picker}
                            handler={handleStartTime}
                            name="start_time"
                            hasError={hasError("start_time")}
                            errorMessage={errorMessage("start_time")}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DateTimer
                            label={t('End Date')}
                            data={schedule.end_time_picker}
                            handler={handleEndTime}
                            name="end_time"
                            hasError={hasError("end_time")}
                            errorMessage={errorMessage("end_time")}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t('Speakers & Coordinators')}
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={6}>
                        <AutoCompleteField
                            label={t('Speakers')}
                            placeholder={t("Choose a speaker...")}
                            model={speakers}
                            data={schedule.speakers}
                            onChange={onSpeakersChange}
                            handleItemsOrder={handleSpeakersOrder}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <AutoCompleteField
                            label={t('Coordinators')}
                            placeholder={t("Choose a coordinator...")}
                            model={coordinators}
                            data={schedule.syntonistes}
                            onChange={onSyntonistesChange}
                            handleItemsOrder={handleSyntonistesOrder}
                        />
                    </Grid>

                    <PanelBottomBar classes={"panel_utilities_element basicinfo"} close={props.triggerdrawer(false)} style={{justifyContent:"space-between"}} submit={onSubmit} />


                </Grid>
            </div>
        </div>
    )

}