import enLocale from "date-fns/locale/en-US";
import elLocale from "date-fns/locale/el";

export const datepickerLocales = {
    en: enLocale,
    el: elLocale,
};

export const datepickerCancelLabelLocales = {
    en: "CANCEL",
    el: "ΑΚΥΡΩΣΗ",
};

export const datepickerClearLabelLocales = {
    en: "CLEAR",
    el: "ΚΑΘΑΡΙΣΜΑ",
};