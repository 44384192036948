import React from 'react';
import {useTranslation} from "react-i18next";
import * as constants from "../../../Panel/common/Constants";

export default function StageQuestions(props){

    const {t, i18n} = useTranslation();

    return(

            <>
                {props.stageStatus != null && props.stageStatus == 1
            ?
                <div className="card left-top" style={{borderRadius:'20px',marginBottom:'20px'}}>
                    <div className="card-header" style={{borderBottom: '1px solid var(--divider-color)', backgroundColor: '#ffffff',borderRadius:'16px 16px 0 0'}}>
                        <h6 style={{fontSize: '14px', lineHeight: '23px', color: '#000000', fontWeight: 700, margin: '10px'}}>{t("Questions")}</h6>
                    </div>
                    <div className="card-body" style={{textAlign:"center"}}>
                        {
                            props.stageStatus != null && props.stageStatus == 1 && props.stage.has_questions_form == 1
                                ? <>
                                    {/*<div className="payer-info" style={{display: 'flex', padding: '23px 36px',backgroundColor:"transparent"}}><img style={{height: '42px', marginRight: '10px'}} src={constants.LocalUrl + "/hand.png"} />*/}
                                    {/*    <p style={{fontSize: '12px', lineHeight: '16px', color: '#000000', fontWeight: 500}} dangerouslySetInnerHTML={{__html:  props.stage?props.stage.msg_live:""}}/><br />*/}
                                    {/*</div>*/}
                                    <div className="send-questions" style={{margin:"10px 0 0 0", padding: '20px 19px', width: '100%', display: 'flex', backgroundColor: '#E5E4E4', borderRadius: '15px', boxShadow:'-5px 3px 40px #5e5c9a1a'}}>
                                        <input onChange={props.handleQuestionData} value={props.question} type="text" style={{borderStyle: 'none', width: 'inherit',backgroundColor:"#E5E4E4"}} placeholder={t("You_can_ask_anything_here")} />
                                    </div>
                                    <button onClick={props.sendQuestion} className="btn btn-primary" type="button" style={{margin:"0 auto",fontSize: '15px', lineHeight: '24px', color: '#ffffff', fontWeight: 600, borderStyle: 'none', backgroundColor: 'var(--divider-color)', marginTop: '10px', borderRadius: '50px', paddingRight: '30px', paddingLeft: '30px'}}>{t('SEND')}</button>
                                </>
                                : null
                        }
                        <hr/>
                        {
                            props.questions && Object.values(props.questions).map(q => (
                            <div style={{paddingLeft: '10px', borderLeft: '3px solid var(--divider-color)'}}>
                                <h6 style={{fontSize: '10px', lineHeight: '16px', color: '#8e8e8e', fontWeight: 500, marginBottom: 'auto'}}>{q.sent_at}</h6><a href="#" style={{fontSize: '14px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{q.name}</a>
                                <p style={{fontSize: '14px', lineHeight: '16px', color: '#000000', fontWeight: 400}}>{q.message}</p>
                                <hr/>
                            </div>))
                        }



                    </div>
                </div>

            : ""}

            </>

    )

}