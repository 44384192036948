// import {toast} from "react-toastify";

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

setInterval(() => {
    axios.post(constants.LocalUrl + '/data/csrf').then(response => {
        document.getElementsByName('csrf-token')[0].content = response.data;
    });
}, 60000);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
import * as constants from "./components/Panel/common/Constants";
import "./auth/user"

window.Pusher = require('pusher-js');
if(process.env.MIX_LITE_MODE == "production"){
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        wsHost: window.location.host,
        wsPort: 80,
        wssPort: 443,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: 'https://'+window.location.host+'/broadcasting/auth',
        encrypted: true,
        forceTLS: true,
        disableStats: true,
        csrfToken: document.getElementsByName('csrf-token')[0].content
        ,auth: {
            headers: {
                'Authorization': 'Bearer '+document.getElementsByName('api-token')[0].content,
                'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content
            }
        }
    });
}else{
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        wsHost: window.location.host,
        wsPort: process.env.MIX_LARAVEL_WEBSOCKETS_PORT,
        wssPort: process.env.MIX_LARAVEL_WEBSOCKETS_PORT,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: 'http://'+window.location.host+'/broadcasting/auth',
        encrypted: true,
        forceTLS: false,
        disableStats: true,
        csrfToken: document.getElementsByName('csrf-token')[0].content
        ,auth: {
            headers: {
                'Authorization': 'Bearer '+document.getElementsByName('api-token')[0].content,
                'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content
            }
        }
    });
}


window.temp_user_id = document.getElementById('temp_user_id').content;

window.channel_user_notifications = window.Echo.private('app.user.notifications.'+window.temp_user_id)
    .listen('.app.user.notification-sent', (data) => {
        let options = {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        };
        if (data.type == 'success') { toast.success(data.message, options); }
        else if (data.type == 'error') { toast.error(data.message, options); }
        else if (data.type == 'warning') { toast.warning(data.message, options); }
        else if (data.type == 'info') { toast.info(data.message, options); }
    });


window.channel_stage = window.Echo.channel('stage');

window.toastOptions = {
    position: "top-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

window.stageTrackInterval = null;