import React, {useEffect, useState} from 'react'
import axios from "axios";
import * as constants from "../../../Panel/common/Constants";
import Loader from "../../../common/Loader";
import {NavLink} from "react-router-dom"
import {useTranslation} from "react-i18next";
import UserCard from "../../../common/UserCard";


export default function StageIndividualSessionCard (props){

    const {t, i18n} = useTranslation();
    const [stage,setStage] = useState(null);
    const [currentSession,setCurrentSession] = useState(null);
    const [listens, setListens] = useState(false);

    useEffect(() => {
        const loadPost = () => {
            axios.post(constants.LocalUrl + "/data/get_stage_current_session").then((response) => {
                setStage(response.data.stage);
                setCurrentSession(response.data.current_session);
            });
        }
        // Call the function
        loadPost();
        if (!listens) {
            window.channel_stage.listen('.stage.change_card', (data) => {
                console.log('listened .stage.change_card');
                setStage(data.stage);
                setCurrentSession(data.current_session);
            });
            setListens(true);
        }
    }, []);


    if (stage == null) {
        return <Loader/>
    }

    return <>
        <div className="card left-top" style={{borderRadius:'20px',marginBottom:'20px'}}>
            {/*<div className="card-header" style={{borderBottom: '1px solid #EFC000', backgroundColor: '#ffffff',borderRadius:'16px 16px 0 0'}}>*/}
            {/*    <ul className="nav nav-tabs card-header-tabs left-top-header" style={{backgroundColor: '#ffffff'}}>*/}
            {/*        {props.stage && Object.values(props.stage['stream_code']).map((stream,key) => (*/}
            {/*            <li className={key==0?'nav-item active':'nav-item'}><a className="nav-link" stream={key} onClick={props.changeStream} href="#" style={{color: 'black', fontWeight: 700, margin: '0px 10px 10px 0px', borderBottom: 'initial', borderLeft: 'none', borderTop: 'none', backgroundColor: '#ffffff',fontSize:'18px'}}>{stream['name']}</a></li>*/}
            {/*        ))}*/}

            {/*    </ul>*/}
            {/*</div>*/}
            <div className="card-body speakers_coordinators_stage_card">
                {
                    currentSession == null
                        ? <>
                            {/*<h6 className="card-title" style={{fontSize: '14px', fontWeight: 700}}>{stage.title}</h6>*/}
                            {/*<p className="card-text" style={{fontWeight: 500, fontSize: '12px', paddingBottom: '10px', borderBottom: '0.5px solid grey'}}></p>*/}
                        </>
                        : <>
                            <h6 className="card-title" style={{fontSize: '14px', fontWeight: 700}}>{currentSession.title}</h6>
                            <p className="card-text" style={{fontWeight: 500, fontSize: '12px', paddingBottom: '10px', borderBottom: '0.5px solid grey'}} dangerouslySetInnerHTML={{__html:  currentSession?currentSession.subtitle:""}}/>
                        </>
                }
            </div>
            <div className="card-body">

                {currentSession != null && currentSession.omilites
                        ? <>
                            {currentSession.omilites.length > 0 ? <>
                                <h6 style={{fontSize: '16px', lineHeight: '16px', color: '#000000', fontWeight: 700, }}>{t('Speakers')}</h6>
                                {currentSession.omilites && Object.values(currentSession.omilites).map(user => (
                                    <UserCard user={user} />
                                    // <div>
                                    //     {/*<NavLink as="a" to={constants.LocalUrl + "/profile/" + user.id} style={{fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{user.name}</NavLink>*/}
                                    //     <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 500, marginBottom: 'auto'}}>{user.jobtitle}</h6>
                                    //     <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 700}}>{user.company}</h6>
                                    // </div>
                                ))}
                            </> : null }
                        </>
                        : null
                }
                {
                    currentSession != null && currentSession.syntonistes
                        ? <>
                            {currentSession.syntonistes.length > 0 ? <>
                                <h6 style={{fontSize: '16px', lineHeight: '16px', color: '#000000', fontWeight: 700, paddingTop: '15px'}}>{t('Coordinators')}</h6>
                                {currentSession.syntonistes && Object.values(currentSession.syntonistes).map(user => (
                                    <UserCard user={user} />
                                    // <div>
                                    //     <NavLink as="a" to={constants.LocalUrl + "/profile/" + user.id} style={{fontSize: '16px', lineHeight: '16px', color: '#615dfa', fontWeight: 500, textDecoration: 'none'}}>{user.name}</NavLink>
                                    //     <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 500, marginBottom: 'auto'}}>{user.jobtitle}</h6>
                                    //     <h6 style={{fontSize: '14px', color: '#535353', fontWeight: 700}}>{user.company}</h6>
                                    // </div>
                                ))}
                            </> : null }
                        </>
                        : null
                }
            </div>
        </div>
    </>;

}