import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import {toast} from "react-toastify";
import React from "react";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as constants from "../../Panel/common/Constants";

export default function LiveonChatDropzone(props) {

    // specify upload params and url for your files
    const getUploadParams = ({meta}) => {
        return {
            url: "/share/rooms/"+props.room_id,
            acceptedFiles: "image/*,application/pdf",
            dictFileTooBig: "file_too_large",
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
        }
    }
    // called every time a file's `status` changes
    const handleChangeStatus = ({meta, remove}, status) => {
        // console.log(status, meta, file)
        // console.log(file)
        // console.log(file.name)
        // console.log(files[0])

        let options = {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        };

        if (status === 'headers_received') {
            toast.success(`${meta.name} uploaded!`, options);
            props.forFileUpload(`<a target="_blank" href="/shares/rooms/${props.room_id}/${meta.name}">${meta.name}</a>`);
            remove();
        } else if (status === 'aborted') {
            toast.error(`${meta.name} failed!`, options);
        }
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            canCancel={false}
            inputContent={
                <div className="chat-window-attach-file">
                    <AttachFileIcon/>
                </div>
            }
            accept={constants.acceptedUploadMimeGroups.all}
            maxSizeBytes={constants.acceptedUploadFileSizesInBytes.all}
            multiple={false}
            maxFiles={1}
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
        />
    )
}
