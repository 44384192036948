import React, {useContext} from 'react';
import Typography from "@material-ui/core/Typography";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
    datepickerCancelLabelLocales,
    datepickerClearLabelLocales,
    datepickerLocales
} from "./Language/DatepickerLocales";
import PageProvider from "../Page/PageContext";
import Loader from "./Loader";




export default function DateTimer(props){

    const {settings} = useContext(PageProvider);

    if(!props.data){
        return <Loader />;
    }
    return(
        <div>
            <Typography style={{marginBottom:"15px",marginLeft:"20px"}} alignLeft variant="h6" gutterBottom>
                {props.label} {props.required?"*":null}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[props.localeLanguage]}>
                <DateTimePicker
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    ampm={true}
                    label=""
                    value={props.data?props.data:null}
                    required error={props.hasError}
                    helperText={props.errorMessage}
                    minDate={settings.EVENT_DATE}
                    maxDate={settings.EVENT_DATE_TO}
                    fullWidth
                    onChange={props.handler}
                    onError={console.log}
                    format={settings.EVENT_DATETIME_FORMAT_UI}
                    cancelLabel={datepickerCancelLabelLocales[props.localeLanguage]}
                    clearLabel={datepickerClearLabelLocales[props.localeLanguage]}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}