import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import NewSchedule from './components/NewSchedule';
import LoadedSchedule from './components/LoadedSchedule';
import ScheduleCreateButton from './components/ScheduleCreateButton';
import DrawerWindow from "../../../Panel/common/DrawerWindow";
import TableSchedules from "./components/TableSchedules";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../../Panel/common/Constants"
import Grid from '@material-ui/core/Grid';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export default function Schedule() {


    const [drawerstate, setDrawerstate] = useState({
            open: false,
            id: null
        }
    );
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);


    const toggleChildMenu = (attached,sponsor_id) => (e) => {
        setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
    };

    const tttt = (attached,sponsor_id) =>  {
        setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
    };

    const loadPost = async () => {

        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        // Await make wait until that
        // promise settles and return its reult
        const response = await axios.post(
            constants.LocalUrl + "/data/get_all_schedules");

        // After fetching data stored it in posts state.
        setSchedules(response.data);

        // Closed the loading page
        setLoading(false);
    }

    const newScheduleHandler = (data) => {
        // setSchedules((schedules) => data);
        loadPost();
    };

    const updateScheduleHandler = (data) => {
        // setSchedules((schedules) => data);
        loadPost();
    };

    useEffect(() => {

        // Call the function
        loadPost();
    }, []);



    if(loading === true){
        return <CircularProgress />
    }

    let layout_content;

    if (schedules === null || schedules.length == 0) {
        layout_content = <div><ScheduleCreateButton onClick={toggleChildMenu(true)}/></div>;
    }

    if (schedules !== null && schedules.length > 0) {
        layout_content = <div style={{width:"100%"}}><ScheduleCreateButton type={"small"} onClick={toggleChildMenu(true)}/><br /><br /><TableSchedules props={schedules}  triggerdrawer={toggleChildMenu} triggerdataload={newScheduleHandler} /></div>;
    }

    return (
        <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"528px", height:"528px", overflowX: "hidden"}} >
        <Grid container spacing={3} style={{padding:'20px'}}>

            {layout_content}

            <DrawerWindow open={drawerstate.open}>
                {drawerstate.id?<LoadedSchedule props={drawerstate.id} posts={schedules} triggerdrawer={toggleChildMenu} ooo={tttt} triggerdataload={updateScheduleHandler}/>:<NewSchedule posts={schedules} triggerdrawer={toggleChildMenu} ooo={tttt} triggerdataload={newScheduleHandler}/>}
            </DrawerWindow>
        </Grid>
        </SimpleBar>
    );

}

