import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {createContext} from "react";


const TooltipContext = createContext();

export function TooltipProvider({children}) {

    const [show, setShow] = useState(false)

    const BoldTooltip = withStyles((theme) => ({
        tooltip: {
            // backgroundColor: 'rgba(0, 0, 0, 0.87)',
            backgroundColor: 'rgba(255, 255, 255, 0.87)',
            // color: '#ffffff',
            color: 'rgba(0,0,0,.87)',
            boxShadow: theme.shadows[1],
            fontSize: 15,
            fontWeight: "bold",
        },
        arrow: {
            // color: 'rgba(0, 0, 0, 0.87)',
            color: 'rgba(255, 255, 255, 0.87)',
        },
    }))(Tooltip);

    const setShowTooltip = () => () => {
        console.log("setShowTooltip",show)
        setShow(!show)
    }

    return(
        <TooltipContext.Provider
            value={{BoldTooltip}}
            open={show}
        >
            <div onClick={() => setShowTooltip()}>
                {children}
            </div>
        </TooltipContext.Provider>
    )

}


export default TooltipContext;
