import React, {useEffect} from "react";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";

import {useState} from "react";

export default function MyUploader (props){

    const {t, i18n} = useTranslation();
    const [changeImage, setChangeImage] = useState(false);
    const [removeImage, setRemoveImage] = useState(false);
    const [preloadImage, setPreloadImage] = useState("");
    // specify upload params and url for your files
    useEffect(() => {

        if(props.image && props.image.length>0 && props.image!="/"){
            setPreloadImage(props.image);
        }else{
            setPreloadImage("/ethos_img/placeholder.jpg");
        }

    }, []);
    const getUploadParams = ({ meta }) => { return { url: '/data/save_file',headers:{'X-CSRF-Token' : $('meta[name="csrf-token"]').attr('content')},fields: { filePath: props.filePath, sponsorId:props.sponsor_id,type:props.type}}}
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, remove, file, xhr }, status) => {
        if (status === 'headers_received') {
            // let response = JSON.parse(xhr.response);
            // console.log("handleChangeStatus",xhr.response)

            // console.log(status, meta, file, "xhr.response",response)

            // remove()
        }

        // console.log(status)

        // console.log(file.name)
        // props.holdFile(file)
        props.holdFilename(file.name)


        // console.log(files[0])
    }

    // // receives array of files that are done uploading when submit button is clicked
    // const handleSubmit = (files, allFiles) => {
    //
    //
    //     console.log(files.map(f => f.meta))
    //     allFiles.forEach(f => f.remove())
    //
    // }

    const handleOnDrop = (acceptedFiles) => {
        // console.log("handleOnDrop")
        // console.log(acceptedFiles)
        // console.log("handleOnDrop___")
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result
            console.log(binaryStr)
        }
        reader.readAsArrayBuffer(file)
    }

    const Layout = ({status, input, previews, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {

        return (
            <div className="single_pic_dropzone">
                {changeImage ? previews : <div className="dropzone_loaded_image">
                    {props.image && props.image.length > 0 && props.image != "/" && !preloadImage.endsWith('/placeholder.jpg') ? <a onClick={() => deleteImage()}><div className="dzu-previewStatusContainer before"><span
                        className="dzu-previewButton"
                        style={{backgroundImage:<CloseIcon/>}}><CloseIcon/></span></div></a> : null}
                    <img src={preloadImage} alt=""/></div>}


                <div {...dropzoneProps}>
                    <label className="fake_Dropzone_button">
                        {t('UPLOAD')}
                        {/*{files.length < maxFiles && input}*/}
                        {input}
                    </label>
                </div>

                {/*{files.length > 0 && submitButton}*/}
            </div>
        )
    }

    const input_c = () => {
        return (
            <div style={{border:"2px rgba(225, 224, 225) dashed ",padding:"20px",borderRadius:"20px"}}> <p style={{color:"#39BB0F"}} dangerouslySetInnerHTML={{__html: t("Add_a_file")}}/></div>
        )
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
            // submitButton={submm}
            // LayoutComponent={Layout}
            maxFiles={1}
            // LayoutComponent={Layout}

            multiple={false}
            canCancel={false}
            inputContent={input_c}
            styles={{
                dropzone: { width: 400, height: 200, overflow: 'hidden'},
                dropzoneActive: { borderColor: 'green' },
                dzuInputLabel: {marginBottom: '0'}
            }}
        />
    )
}
