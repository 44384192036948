import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as constants from "../../../Panel/common/Constants";
import Loader from "../../../common/Loader";
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import UserCard from "../../../common/UserCard";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import FrontEmptyDiv from "../../../common/FrontEmptyDiv";
import PageProvider from "../../PageContext";


export function AgendaContentMobile(props, settings = null) {

    const {t, i18n} = useTranslation();
    const [sessions, setSessions] = useState([]);

    useEffect(() => {


        const loadAgendaData = () => {

            setSessions(props.data);

        }

        // Call the function
        loadAgendaData();


    }, []);

    if (sessions.length === 0) {
        return <Loader/>;
    }



    const notifyHandler = () => (index) => {

        var i = index.target.attributes[1].value;
        const response = axios.post(constants.LocalUrl + '/data/notify_user', {session_id: i}).then(response => {
            props.reloader();
        });
    }

    return (

            props.data && Object.values(props.data).map((agenda_day,index) => (
            Object.values(agenda_day).map(session => (



                <TabPanel value={props.tvalue} index={index} style={{padding: '5px 0'}}>

                    <div className="d-lg-flex session_description" >
                        <div className="session_time">
                        <img src="/images/session-clock.png" style={{width: '20px',height: '20px',margin: '3px 8px 3px 0'}}/>
                        <p style={{
                            fontSize: '20px',
                            fontWeight: 400
                        }}>{session.starts_at_time} - {session.ends_at_time}</p>
                        </div>
                        <p style={{fontSize: '22px',margin:"6px 0"}}><strong>{session && session.title?session.title[i18n.language]:""}</strong></p>
                    </div>
                    <hr style={{height: '1px', margin: 0, color: '#b3b3b3'}}/>

                    <div>
                        {
                            session && session.subtitle && session.subtitle[i18n.language] && session.subtitle[i18n.language].trim().length > 0
                                ? <>
                                    <h1 className="session_subtitle" dangerouslySetInnerHTML={ {__html:  session.subtitle[i18n.language]}}/>
                                    <hr style={{height: '1px', margin: 0, color: '#b3b3b3'}}/>
                                </>
                                : null
                        }

                        <div className="session_content" style={{display: "flex", justifyContent: "space-between"}}>
                            <div className="session_content_inner" style={{width: "50%"}}>
                                {session.coordinatorz ? <div style={{padding: '18px 0 0 30px'}}>
                                    <h1>{t('COORDINATOR')}</h1>
                                    {session.coordinatorz && session.coordinatorz.map(coordinator => (
                                        <UserCard user={coordinator} />
                                    ))}
                                </div> : ""}


                                {session.speakerz ? <div style={{padding: '18px 0 0 30px'}}>
                                    <h1>{t('SPEAKERS')}</h1>
                                    {session.speakerz && session.speakerz.map(speaker => (
                                        <UserCard user={speaker} />
                                    ))}
                                </div> : ""}
                                {/*</div>*/}
                                {/*    <div>*/}
                            </div>
                            <div className="agenda_buttons">
                                {!session.user_has_subscribed_to_session
                                    ?
                                    <button className="btn btn-primary" onClick={notifyHandler()}
                                            session-id={session.id}
                                            type="button" style={{
                                        marginBottom: "10px",
                                        borderColor: 'transparent',
                                        fontWeight: "bold",
                                        lineHeight: '17px',
                                        fontSize: '14px',
                                        color: '#fff',
                                        background: '#09bd3c',
                                        display: 'block',
                                        minWidth: '242px',
                                        padding: '10px 20px',
                                        borderRadius: '40px',
                                        textAlign: 'center',
                                        maxHeight: "41px"
                                    }}>
                                        {t('NOTIFY ME')}
                                    </button>
                                    :
                                    ''
                                }
                                <AgendaAddToCalendar session_id={session.id}></AgendaAddToCalendar>
                            </div>
                        </div>


                    </div>

                </TabPanel>

        ))
        ))

    );
}

//  props.session_id
export function AgendaAddToCalendar(props) {
    const {t, i18n} = useTranslation();
    const [links, setLinks] = useState({});
    const [open, setOpen] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        dropdown: {
            position: 'absolute',
            top: '100%',
            right: 0,
            left: '-50%',
            zIndex: 50,
            border: '1px solid',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            width: '150%',
            minWidth: '242px'
        },
    }));
    const classes = useStyles();

    const loadLinks = () => {
        axios.post(constants.LocalUrl + '/data/sessionCalendarLinks', {session_id: props.session_id}).then(response => {
            setLinks(response.data);
        });
    }

    useEffect(() => {
    }, []);


    const handleClick = () => {
        loadLinks();
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <button className="btn btn-primary" type="button" onClick={handleClick} style={{
                    borderColor: 'transparent',
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#fff',
                    backgroundColor: '#676868',
                    display: 'block',
                    marginBottom: '10px',
                    minWidth: '242px',
                    padding: '10px 20px',
                    borderRadius: '40px',
                    textAlign: 'center',
                    fontWeight:"bold",
                    maxHeight: "41px"
                }}>
                    {t('add_to_calendar')}
                </button>
                {open ? (
                    <div className={classes.dropdown}>
                        {
                            Object.keys(links).length > 0
                                ? Object.keys(links).map((key) =>
                                    <a className="dropdown-item" href={links[key].href} title={links[key].title}
                                       target="_blank">
                                        <img src={'/app/calendars/' + key + '.png'} alt={links[key].title}
                                             style={{width: '45px', display: 'inline'}} width="45" border="0"/>
                                        {links[key].title}
                                        {links[key].is_online ? <span className="small text-muted">{t('online')}</span> : ''}
                                    </a>
                                )
                                : ''
                        }
                    </div>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export function Agenda() {

    const {t, i18n} = useTranslation();
    const [agenda, setAgenda] = useState({});
    const [agendaDates, setAgendaDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabContent, setTabContent] = useState([]);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const {setSettingsWindow,settings} = useContext(PageProvider);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadSponsor = async () => {

        setLoading(true);

        const response = await axios.post(constants.LocalUrl + '/data/get_agenda_data').then(response => {

            // Closed the loading page
            setLoading(false);

            // After fetching data stored it in posts state.
            setAgenda(response.data[0]);

            // Set content displayed on tab
            let content = Object.entries(response.data[0])
            setAgendaDates(response.data[1]);
            setTabContent(content[0][1])
        });

    }

    useEffect(() => {

        // Call the function
        loadSponsor();

    }, []);


    const changeTabHandler = () => (index) => {


        // Match date and set new content for tab
        var i = index.target.attributes[3].value;
        let content;
        let o = Object.entries(agenda).map((vald) => {
            if (vald[0] == i) {
                content = vald[1];
            }
            // console.log(vald)
        })

        // console.log("o")
        // console.log(content)
        setTabContent(content);

    }

    if (loading === true) {
        return <Loader/>
    }
    let i = 0;

    if (tabContent.length == 0) {
        return <FrontEmptyDiv className={"container agenda-wrapper agenda tab-content"}>
            <div dangerouslySetInnerHTML={{__html: t('front_agenda_no_data')}}></div>
            <br/>
            <br/>
            {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator"))?<>
                <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_speakers_1')}}></span>
                <span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:3})}><b>{t("AGENDA")}</b></span>
                <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_speakers_2')}}></span><span className="set_pointer"><b>{t("SETTINGS")}</b></span>.</>:null}
        </FrontEmptyDiv>
    }

    return (
        <div style={{width: "100%", backgroundColor: "rgb(250, 250, 250)"}}>
            <div id="agenda-individual" className="container agenda-wrapper">
                <header className="page_header" style={{marginBottom:"20px",color:"rgba(0,0,0,0.87)"}}>{t('AGENDA')}</header>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{

                        style: {
                            backgroundColor: "var(--divider-color)",
                            borderRadius: "4px",
                            right: "0",
                            zIndex: "1"
                        }
                    }}
                >
                {/*<ul className="nav nav-tabs" role="tablist" style={{borderBottom: 'none', marginTop: '20px'}}>*/}
                    {agendaDates && Object.values(agendaDates).map((val, index) => (
                        <Tab {...a11yProps(index)} className={index == 0 ? "nav-item active" : "nav-item"} role="presentation" dd={index}
                            onClick={changeTabHandler(val)} style={{marginRight: '5px', marginLeft: '5px'}} label={val.date_formatted}/>
                    ))}
                {/*</ul>*/}
                </Tabs>

                <div className="tab-content agenda">
                    <AgendaContentMobile tvalue={value} data={agenda} reloader={loadSponsor}/>
                </div>
            </div>
        </div>
    )

}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

$(document).ready(function () {
    $(".nav-tabs .nav-item:first-child").addClass('active');
});
// $(".nav-tabs .nav-item:first-child").addClass('active');
$(document).on("click", ".nav-tabs .nav-item", function () {
    $(".nav-tabs .nav-item").removeClass('active');
    $(this).addClass('active');
})

export default Agenda;
