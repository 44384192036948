import React from 'react';
import {createMedia} from "@artsy/fresnel";
import AgendaIndividual from "./AgendaIndividual";
import AgendaMobile from "./AgendaMobile";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export default function Agenda_layout(){


    return (
        <MediaContextProvider>
            {/*<>*/}
            <Media at='mobile'>
                <AgendaMobile />
            </Media>

            <Media at='computer'>
                <AgendaIndividual />
            </Media>



            {/*</>*/}
        </MediaContextProvider>

    )

}