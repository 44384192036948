import React, {Component, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import * as constants from "../../Panel/common/Constants"
import {NavLink} from 'react-router-dom';
import {useTranslation, withTranslation} from "react-i18next";
import InlineTranslate from "../../common/Language/InlineTranslate";
import FrontEmptyDiv from "../../common/FrontEmptyDiv";
import PageProvider from "../PageContext";
import Loader from "../../common/Loader";

export default function Sponsors() {

    const {t, i18n} = useTranslation();
    const [sponsors, setSponsors] = useState([]);
    const {settings, setSettingsWindow} = useContext(PageProvider);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true)
        axios.post(constants.LocalUrl + `/data/get_all_sponsors_data`)
            .then(res => {
                const sponsors = res.data;
                setSponsors(sponsors);
                setLoading(false)
            })
    }, []);

    if(loading === true){
        return <Loader />
    }

    // if(Object.keys(sponsors).length === 0){
    //     return <FrontEmptyDiv className={"container agenda-wrapper agenda tab-content"}>
    //         <div dangerouslySetInnerHTML={{__html: t('front_agenda_no_data')}}></div>
    //         <br/>
    //         <br/>
    //         {/*{console.log("settings.user",settings.user)}*/}
    //         {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator"))?<>
    //             <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_sponsors_1')}}></span><span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:4})}><b>{t("SPONSORS")}</b></span><span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_sponsors_2')}}></span><span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:4})}><b>{t("SETTINGS")}</b></span>.
    //         </>:null}
    //     </FrontEmptyDiv>
    // }

    return (
        <div>
            <section id="sponsors" className="section align-center" style={{marginTop: "30px"}}>
                <div className="container" style={{textAlign: 'center'}}>
                            {/*<span className="icon section-icon icon-documents-bookmarks-12"*/}
                            {/*      style={{display: 'block', marginBottom: '20px', fontSize: '50px', lineHeight: 1}}/>*/}
                    <h3 style={{
                        fontSize: '50px',
                        letterSpacing: '-0.05em',
                        fontWeight: 400,
                        marginBottom: '10px'
                    }}>
                        {t('Sponsors')}
                    </h3>

                    {sponsors && Object.keys(sponsors).length === 0?
                         <FrontEmptyDiv className={"container agenda-wrapper agenda tab-content"}>
                        <div dangerouslySetInnerHTML={{__html: t('front_agenda_no_data')}}></div>
                        <br/>
                        <br/>

                    {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator"))?<>
                        <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_sponsors_1')}}></span><span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:4})}><b>{t("SPONSORS")}</b></span><span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_sponsors_2')}}></span><span className="set_pointer "><b>{t("SETTINGS")}</b></span>.
                        </>:null}
                        </FrontEmptyDiv>
                    :
                    <>
                        <br/>
                        <br/>
                        <div className="sponsors">
                    {sponsors && sponsors.map(sponsor =>
                        <div key={sponsor.id} className="sponsor big"><NavLink className="item"
                        to={constants.LocalUrl + "/sponsors/" + sponsor.id}><img
                        src={constants.LocalUrl + sponsor.logo} alt={sponsor.title.el}/></NavLink></div>
                        )}

                        </div>
                        </>}
                </div>
            </section>
        </div>
    );

}