import {createContext, useEffect, useState} from "react";
import * as constants from "../Panel/common/Constants";
import axios from "axios";

const ChatContext = createContext();

export function ChatProvider({children}){

    const [reloadData,setReloadData] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [chatWindows, setChatWindows] = useState([]);

    if (!window.temp_user_id || window.temp_user_id == 0) {
        return false;
    }

    useEffect(() => {

        const user = async () => {

            await axios.post(constants.LocalUrl + '/data/chat_get_user_conversations')
                .then(res => {
                    const conversationz = res.data;
                    setConversations(conversationz);
                })

            setReloadData(false);
        }

        // Call the function
        if(reloadData === true){
            user();
        }
    }, [reloadData]);

    // const [activatedSteps,setActivatedSteps] = useState([1]);
    // console.log(stepButtonState)
    // const handleStepButtonState = (status = false) => {
    //     setStepButtonState((prevState) => [...prevState,true])
    // }

    return(
        <ChatContext.Provider value={{setReloadData,conversations,setConversations,chatWindows,setChatWindows}}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatContext;