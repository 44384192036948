import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    rootBlue: {
        width: '100%',
        background: '#17a2b8',
        color: '#FFFFFF'
    },
    rootGreen: {
        width: '100%',
        background: '#28a745',
        color: '#FFFFFF'
    },
    rootYellow: {
        width: '100%',
        background: '#ffc107',
        color: '#FFFFFF'
    },
    rootRed: {
        width: '100%',
        background: '#dc3545',
        color: '#FFFFFF'
    },
    rootWhite: {
        width: '100%',
        background: '#ffffff',
        color: '#000000',
        borderRadius: '10px',
        boxShadow: '0px 0px 2px 2px #5e5c9a1a',
    },
    rootWhiteBig: {
        width: '100%',
        height: '400px',
        background: '#ffffff',
        color: '#000000',
        borderRadius: '10px',
        boxShadow: '0px 0px 2px 2px #5e5c9a1a',
    },
    footerDimmed: {
        backgroundColor: 'rgba(0,0,0,.15)',
        padding: 0,
        alignItems: "center"
    },
    footerButton: {
        color: '#FFFFFF',
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 15,
    },
    title_bold: {
        fontSize: 15,
        fontWeight: "bold"
    },
    title_semi: {
        fontSize: 24,
    },
    title_semi_bold: {
        fontSize: 24,
        fontWeight: "bold"
    },
    title_number: {
        fontWeight: "bold",
        fontSize: '2.5rem'
    },
    pos: {
        marginBottom: 12,
    },
    table: {
        minWidth: '100%',
        width: '100%',
    },
});

export default function StatCardMini({className, number, number_title, number_font_size, direction}) {

    const {t, i18n} = useTranslation();

    useEffect(() => {
    }, []);

    const classes = useStyles();

    return (
        <Grid className={className} container alignItems={'center'} spacing={0} direction={direction??"column"}>
            <Grid item>
                <Typography variant="h3" component="h3" className={classes.title_number} align={'center'} style={{fontSize: number_font_size||'auto'}}>
                    {number??0}
                </Typography>
            </Grid>
            {
                number_title
                ? <Grid item>
                        <Typography variant="h3" component="h3" className={classes.title} align={'center'} style={{height: 35, overflow: 'hidden'}}>
                            {number_title}
                        </Typography>
                    </Grid>
                : null
            }
        </Grid>
    );

}

