// import i18n from "../../../locale/i18n";

export const LangFlags = {
    el:"/app/greece-flag-round.png",
    en:"/app/united-kingdom-flag-round.png"
}

// export const t = (key, options) => {
//     return i18n.t(key, options) + `<span>{key}</span>`;
// }
