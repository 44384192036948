import React, {useRef, useCallback} from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {chartjsBackgroundPlugin} from "../Panel/common/Constants";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



const useStyles = makeStyles({
    root: {
        minWidth: 275,
        maxWidth: "unset",
        width: "100%",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});



export default function VerticalChart(props) {


    const {t, i18n} = useTranslation();
    const ref = useRef(null);
    const classes = useStyles();
    const labels = props.registrations[1];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: t("Registrations per day"),
            },
            customCanvasBackgroundColor: {
                color: 'white',
            }
        },
        scales: {
            y: {
                min: 0,
                ticks: {
                    stepSize: 10
                }
            }
        }
    };

    const data = {
        labels,
        datasets: [
            {
                label: t('# of Users'),
                data: Object.values(props.registrations[0]),
                backgroundColor: 'rgba(57, 187, 15, 0.5)',
            },
        ],
    };

    const donwloadImage = useCallback(() => {
        const link = document.createElement("a")
        link.download = "charts.jpg"
        link.href = ref.current.toBase64Image('image/jpeg', 1)
        link.click()
    },[])


    return (
        // <Card className={classes.root}>
        //     <CardContent>
        <div style={{display:"block",width:"100%",backgroundColor:"#fff"}}>
        <Button style={{float:"right"}} size="large" onClick={donwloadImage} variant="contained" color="primary" component="span">{props.button_text??t('Download')}</Button>
        <Bar style={{backgroundColor:"#fff"}} ref={ref} options={options} plugins={[chartjsBackgroundPlugin]} data={data} />
        </div>
            // </CardContent>
        // </Card>

    );
}
