import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useTranslation} from "react-i18next";
import * as constants from "../../../common/Constants";
import {toast} from "react-toastify";
import {ReactSVG} from "react-svg";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import Loader from "../../../../common/Loader";
import {Tooltip} from 'react-tooltip'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const edit_icon = {
    icon: "/images/edit.svg",
    SendIcon: "/images/panel/send_message_icon.svg",
    delete: "/images/trash.svg",
}

export default function TableSentInvitations(props) {
    const {t, i18n} = useTranslation();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    // const posts = posts.posts;

    var time = 60;
    var initialOffset = 50;
    var i = 0;

    const loadPost = async () => {

        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        // Await make wait until that
        // promise settles and return its reult
        const response = await axios.post(
            constants.LocalUrl + "/data/get_all_invitations");

        // After fetching data stored it in posts state.
        setPosts(response.data);

        // Closed the loading page
        setLoading(false);
    }

    useEffect(() => {
        function handleStatusChange(status) {
        }

        loadPost();
        const reloadInterval = setInterval(function () {
            // $('#clue').text(time-i); // here is the clue
            $('.circle_animation').css('stroke-dashoffset', initialOffset - ((i + 1) * (initialOffset / time)));
            i++;
            if (i >= time) {
                i = 0;
                loadPost();
            }
        }, 1000);

        return () => {
            clearInterval(reloadInterval);
        };
    }, []);


    const deleteRow = (id, index) => {
        const response = axios.post(constants.LocalUrl + "/data/delete_object", {
            object: 'invitation_batch',
            id: id,
            index: index
        }).then(response => {
            if (response.data.status == "error") {
                toast.error(t('item not deleted'), window.toastOptions);
            } else {
                toast.success(t('item deleted'), window.toastOptions);
            }
            loadPost();
        });
    }

    const sendRow = (id, index) => {
        if (window.confirm(t('send_invitation_confirm_text'))) {
            const response = axios.post(constants.LocalUrl + "/data/mark_pending", {
                object: 'invitation_batch',
                id: id,
                index: index
            }).then(response => {
                if (response.data.status == "error") {
                    toast.error(t('item not set to send'), window.toastOptions);
                } else {
                    toast.success(t('item set to send'), window.toastOptions);
                }
                loadPost();
            });
        }
    }

    if (loading === true) {
        return <Loader/>
    }

    const gridStyle = {
        minHeight: 530,
        borderRadius: "20px",
        borderColor: "#fff",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
    }
    const defaultPagination = 5;

    let tableData = [];
    posts.map((row) => {
        row.batch_send && row.batch_send.map((batch, index) => {
            tableData.push({
                id: row.id + '-' + index,
                campaign: row.title,
                list: batch.title,
                status: batch.status,
                updated_at: batch.updated_at,
                actions: {
                    batch: batch,
                    campaign: row.title,
                    campaign_id: row.id,
                    list_id: index,
                    status: batch.status,
                }
            });
        });
    });

    if (tableData.length == 0) {
        return <div style={{width: "100%", backgroundColor: "white"}}>
            <div id="agenda-individual">
                <div style={{padding: 30, textAlign: "center"}}>
                    <div dangerouslySetInnerHTML={{__html: t('marketing_log_empty')}}></div>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            <Tooltip anchorId="reloadTimer" content="When the timer ends, the data will be refreshed automatically"
                     place="left"/>
            <span id="reloadTimer" className='btn-group float-right autoreload' style={{top: '-50px'}}>
                <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
                    <circle className="circle_hint" r="8" cy="15" cx="15" strokeWidth="7" stroke="#eee" fill="none"/>
                    <circle id="circle" className="circle_animation" r="8" cy="15" cx="15" strokeWidth="6"
                            stroke="#6fdb6f" fill="none"/>
                </svg>
            </span>
            {
                Object.values(tableData).length > 0
                    ? <ReactDataGrid
                        theme='green-light'
                        // height
                        // onReady={setGridRef}
                        idProperty="id"
                        style={gridStyle}
                        columns={[
                            {header: t('marketing_log_campaign'), defaultFlex: 2, name: 'campaign'},
                            {header: t('marketing_log_list'), defaultFlex: 2, name: 'list'},
                            {
                                header: t('marketing_log_status'), defaultFlex: 2, name: 'status',
                                render: ({value}) => {
                                    return (<span>{t(value)}</span>)
                                }
                            },
                            {header: t('marketing_log_updated_at'), defaultFlex: 2, name: 'updated_at'},
                            {
                                name: 'actions', header: ' ', minWidth: 100, defaultFlex: 2,
                                render: ({value}) => {
                                    return (
                                        <div>
                                            <Tooltip anchorId={"viewbutton_" + value.campaign_id + "_" + value.list_id}
                                                     content={t("View")} place="top"/>
                                            <Button id={"viewbutton_" + value.campaign_id + "_" + value.list_id}
                                                    className="edit-button" size="medium"
                                                    onClick={props.triggerdrawer(true, value.batch, value.campaign)}
                                                    height="50" color="secondary">
                                                <VisibilityIcon></VisibilityIcon>
                                            </Button>
                                            {value.status == 'draft' ? <>
                                                <Tooltip anchorId={"sendbutton_" + value.campaign_id + "_" + value.list_id}
                                                         content={t("Send")} place="top"/>
                                                <Button id={"sendbutton_" + value.campaign_id + "_" + value.list_id}
                                                        className="delete-button"
                                                        size="medium"
                                                        onClick={() => sendRow(value.campaign_id, value.list_id)}
                                                        height="50" color="secondary">
                                                    <ReactSVG beforeInjection={(svg) => {
                                                        svg.classList.add('svg-class-name');
                                                        svg.setAttribute('style', 'width:29px; padding-left:5px;');
                                                    }} src={edit_icon.SendIcon}/>
                                                </Button>

                                                <Tooltip anchorId={"deletebutton_" + value.campaign_id + "_" + value.list_id}
                                                         content={t("Delete")} place="top"/>
                                                <Button id={"deletebutton_" + value.campaign_id + "_" + value.list_id} className="delete-button"
                                                                                               size="medium"
                                                                                               onClick={() => deleteRow(value.campaign_id, value.list_id)}
                                                                                               height="50"
                                                                                               color="secondary">
                                                    <ReactSVG beforeInjection={(svg) => {
                                                        svg.classList.add('svg-edit-button');
                                                        svg.setAttribute('style', 'width:29px');
                                                    }} src={constants.LocalUrl + edit_icon.delete}/>
                                                </Button>
                                            </> : null}
                                        </div>
                                    )
                                }
                            }
                        ]}
                        dataSource={tableData}
                        enableKeyboardNavigation={false}
                        showCellBorders='horizontal'
                        rowHeight='auto'
                        nativeScroll={true}
                        pagination defaultLimit={defaultPagination}
                    />
                    : <Grid item xs={12}>{t("list is empty")}</Grid>
            }
        </div>
    );
}
