import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

export default function FrontEmptyDiv(props) {
    return (
        <div style={{width: "100%", backgroundColor: "rgb(250, 250, 250)"}}>
            <div id="agenda-individual" className={props.className??''} >
                <div style={{padding: 30, textAlign: "center"}}>
                    {props.children??null}
                </div>
            </div>
        </div>
    )
}