import {createContext, useState} from "react";

const WizardContext = createContext();

export function WizardProvider({children}){

    const [stepButtonState,setStepButtonState] = useState(false);
    const [activatedSteps,setActivatedSteps] = useState([1,2,3,4,5,9]);
    const [saveBasicInfo,setSaveBasicInfo] = useState(false);
    const [saveSettings,setSaveSettings] = useState(false);
    const [saveLanguage,setSaveLanguage] = useState(false);

    // console.log(stepButtonState)
    // const handleStepButtonState = (status = false) => {
    //     setStepButtonState((prevState) => [...prevState,true])
    // }

    return(
        <WizardContext.Provider value={{stepButtonState,setStepButtonState,activatedSteps,setActivatedSteps,saveBasicInfo,setSaveBasicInfo,saveSettings,setSaveSettings,saveLanguage,setSaveLanguage}}>
            {children}
        </WizardContext.Provider>
    )
}






export default WizardContext;