import * as mime from 'react-native-mime-types';

export const LocalUrl = ""

export const acceptedUploadMimeGroups = {
    images: [
            mime.lookup('jpg'),
            mime.lookup('gif'),
            mime.lookup('png'),
        ].join(','),
    pdf: [
            mime.lookup('pdf')
        ].join(','),
    office: [
            mime.lookup('doc'),
            mime.lookup('docx'),
            mime.lookup('xls'),
            mime.lookup('xlsx'),
            mime.lookup('ppt'),
            mime.lookup('pptx'),
        ].join(','),
    all: [
            mime.lookup('jpg'),
            mime.lookup('gif'),
            mime.lookup('png'),
            mime.lookup('doc'),
            mime.lookup('docx'),
            mime.lookup('xls'),
            mime.lookup('xlsx'),
            mime.lookup('ppt'),
            mime.lookup('pptx'),
            mime.lookup('pdf')
        ].join(','),

}

//  KB * 1000 = bytes
export const acceptedUploadFileSizesInBytes = {
    images: 500 * 1000,
    pdf: 5000 * 1000,
    office: 1500 * 1000,
    all: 1000 * 1000
}

export const chartjsBackgroundPlugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
};
