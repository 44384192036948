import React, {useContext} from 'react';
import PageProvider from "../PageContext";
import Loader from "../../common/Loader";
import {useTranslation} from "react-i18next";


export default function AboutContent(){

    const {t, i18n} = useTranslation();
    // useEffect(() => {
    //
    //     const response = axios.post(
    //         constants.LocalUrl + "/data/active_registration_fields");
    //     console.log(response.data);
    //
    // },[])
    const {settings, setSettingsWindow} = useContext(PageProvider);

    if(Object.keys(settings).length === 0){
        return <Loader/>
    }

// console.log(settings.about_text_homepage,settings)
    return (
        <div style={{marginTop:"20px"}}>
            <br/>
            <section id="about" className="section align-center" style={{padding: 0,textAlign:"center"}}>
                <div className="container">
                    {
                        settings.about_text_title[i18n.language].trim().length > 0
                        ? <h3 style={{fontSize: '20px', letterSpacing: '-0.05em', fontWeight: 400, marginBottom: '10px'}}>{settings.about_text_title[i18n.language]}</h3>
                        : <h3 style={{fontSize: '20px', letterSpacing: '-0.05em', fontWeight: 400, marginBottom: '10px'}}>{t('about_title_empty')}</h3>
                    }
                    {
                        settings.about_text_homepage[i18n.language].trim().length > 0
                        ? <div style={{textAlign:'justify',fontSize:"16px",marginTop:"20px"}} dangerouslySetInnerHTML={{__html:  settings.about_text_homepage[i18n.language]}}/>
                        : <div style={{textAlign:'justify',fontSize:"16px",marginTop:"20px"}} >
                            <span dangerouslySetInnerHTML={{__html: t('about_text_empty_1')}}></span>
                            <span className="set_pointer url_alike" onClick={() => setSettingsWindow({
                                activate: true,
                                tab: 5
                            })}><b>{t("WEBSITE_SETTINGS")}</b></span>
                            <span dangerouslySetInnerHTML={{__html: t('about_text_empty_2')}}></span>
                        </div>
                    }
                </div>
            </section>
        </div>
    )

}
