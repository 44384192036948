import React from 'react';

export default function VideoChatParticipants(props){



    return(
        <ul>
            <li style={{textAlign: "left"}}>
                <img style={{borderRadius: "50%", marginRight: "20px", height:"45px", width:"45px"}}
                     src={props.videoRequest.from.user_img} alt=""/>
                {props.videoRequest.from.name}
                {/*({props.channelUsers.includes(props.videoRequest.from.id) ? 'joined' : 'not joined'})*/}
            </li>
            <li style={{textAlign: "left",marginTop:"15px"}}>
                <img style={{borderRadius: "50%", marginRight: "20px",height:"45px", width:"45px"}}
                     src={props.videoRequest.to.user_img} alt=""/>
                {props.videoRequest.to.name}
                {/*({props.channelUsers.includes(props.videoRequest.to.id) ? 'joined' : 'not joined'})*/}
            </li>
        </ul>
    )
}