import {useEffect} from "react";
import "/packages/omnius/css/cookieconsent.css";
import "/packages/omnius/js/cookieconsent.js";
import {useTranslation} from "react-i18next";
import axios from "axios";
import * as constants from "./Panel/common/Constants";

export default function CookieConsent() {

    const {t, i18n} = useTranslation();

    useEffect(() => {

        const loadPost = async () => {
            const response = await axios.post(constants.LocalUrl + "/data/cc_json");
            var cookiesObject = {
                cc_autorun : true,
                cc_delay : 0,
                cc_policy_url : false,
                cc_enable_verbose : false,
                cc_current_lang : response.data.lang,
                cc_auto_language : false,
                cc_autoload_css : true,
                cc_theme_css : '/packages/omnius/css/cookieconsent.css',

                cc_cookie_expiration : 182,

                cc_autoclear_cookies: true,

                cc_accept_callback : function(cookies){
                    axios.post('/gdpr',{cookies: cookies}).then(res2 => document.location.reload());
                },
                cc_languages : [
                    {
                        lang : 'el',
                        modal : {
                            cc_title :  "Χρησιμοποιούμε Cookies",
                            cc_more_text :  "Μάθετε περισσότερα",
                            cc_accept_text : "Αποδέχομαι",
                            cc_description :  'Η εταιρεία μας. τηρεί αυστηρή πολιτική για την προστασία της ιδιωτικότητας των επισκεπτών της ιστοσελίδας μας. Με το παρόν σημείωμα σας παρέχουμε περισσότερες πληροφορίες για το πώς χρησιμοποιούμε “cookies” καθώς και άλλα αυτοματοποιημένα μέσα προκειμένου να συλλέξουμε συγκεκριμένες πληροφορίες σχετικά με τους επισκέπτες των διαδικτυακών τόπων μας, όπως τον αριθμό των επισκεπτών των ιστοσελίδων μας καθώς και τους περισσότερο επισκεπτόμενους διαδικτυακούς τόπους μας, μέσω οποιουδήποτε φυλλομετρητή και συσκευή που χρησιμοποιείται. Με τη συλλογή αυτών των πληροφοριών, μαθαίνουμε όπως να προσαρμόζουμε καλύτερα τις ιστοσελίδες μας και τη διαφήμισή μας προς τους επισκέπτες.',
                        },
                        policy : {
                            ccp_title : "Πολιτική Cookies",
                            ccp_save_text : "Αποθήκευση προτιμήσεων",
                            ccb_table_headers : [
                                {col1: "Όνομα" },
                                {col2: "Πάροχος" },
                                {col3: "Λήξη" },
                                {col4: "Περιγραφή" },
                                {col5: "Τύπος" }
                            ],
                            ccp_blocks : [
                                {
                                    ccb_title : "Τί είναι τα Cookies;",
                                    ccb_description: 'Τα cookies είναι μικρά αρχεία κειμένου που αποθηκεύονται σε κάθε συσκευή σας με την χρήση οποιουδήποτε φυλλομετρητή, όποτε επισκέπτεστε έναν δικτυακό τόπο. Τα cookies μπορεί να τα χρησιμοποιήσουμε για να μας ενημερώσουν για παράδειγμα, εάν έχετε επισκεφτεί την ιστοσελίδα μας στο παρελθόν ή αν είστε νέος επισκέπτης καθώς και να μας βοηθήσουν ώστε να εντοπίσουμε χαρακτηριστικά της ιστοσελίδας μας, για τα οποία μπορεί να ενδιαφέρεστε περισσότερο. Τα cookies μπορεί να βελτιώσουν την διαδικτυακή σας εμπειρία αποθηκεύοντας τις προτιμήσεις σας, καθώς εσείς επισκέπτεσθε μια συγκεκριμένη ιστοσελίδα.'
                                },{
                                    ccb_title : "Εντελώς απαραίτητα Cookies",
                                    ccb_description: 'Τα απολύτως απαραίτητα cookies είναι ουσιαστικής σημασίας για την ορθή λειτουργία του ιστότοπου, σας επιτρέπουν να κάνετε περιήγηση και να χρησιμοποιήσετε τις λειτουργίες του, όπως πρόσβαση σε ασφαλείς περιοχές, φόρμες εγγραφής, λίστα αγαπημένων, χρήση του καλαθιού αγοράς και για λόγους ασφαλείας. Αυτά τα cookies δεν αναγνωρίζουν την ατομική σας ταυτότητα. Χωρίς αυτά τα cookies, δεν μπορούμε να προσφέρουμε αποτελεσματική λειτουργία του ιστότοπου μας.',
                                    ccb_cookies_table : [
                                        {
                                            col1: 'cc_cookie',
                                            col2: 'LiveOn',
                                            col3: '3 μήνες (Από την στιγμή που έγινε η αποδοχή)',
                                            col4: 'Για να ξέρουμε αν αποδέχεστε τα cookies.',
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: response.data.session_cookie,
                                            col2: 'LiveOn',
                                            col3: 'Συνεδρία',
                                            col4: 'Για να μπορούμε να σας παρέχουμε πρόσβαση σε περιοχές της πλατφόρμας LiveOn',
                                            col5: 'HTTP'
                                        },{
                                            col1: 'XSRF-TOKEN',
                                            col2: 'LiveOn',
                                            col3: '8 ώρες',
                                            col4: 'Για να μπορούμε να σας παρέχουμε πρόσβαση σε περιοχές της πλατφόρμας LiveOn',
                                            col5: 'HTTP'
                                        }
                                    ],
                                    ccb_switch : {
                                        value : 'NECESSARY_COOKIES',
                                        enabled : true,
                                        readonly: true
                                    }
                                },{
                                    ccb_title : "Cookies επιδόσεων και ανάλυσης",
                                    ccb_description: 'Αυτά τα cookies συλλέγουν πληροφορίες σχετικά με τον τρόπο που οι επισκέπτες χρησιμοποιούν τον ιστότοπο, ποιες σελίδες επισκέπτονται συχνότερα και αν λαμβάνουν μηνύματα σφαλμάτων από ιστοσελίδες. Αυτά τα cookies συλλέγουν συγκεντρωτικές, ανώνυμες πληροφορίες που δεν ταυτοποιούν κάποιον επισκέπτη. Χρησιμοποιούνται αποκλειστικά για τη βελτίωση των επιδόσεων μίας ιστοσελίδας και μας επιτρέπουν να συλλέξουμε πληροφορίες σχετικά με τη χρήση που κάνετε στις ιστοσελίδες μας, συμπεριλαμβανομένου του περιεχόμενου που επιλέγετε κατά την περιήγησή σας στις ιστοσελίδες μας, προκειμένου αν μετρήσουμε την αποτελεσματικότητα και την αλληλεπίδραση των καταναλωτών με την ιστοσελίδα καθώς και να βελτιώνουμε τις σελίδες μας στην πάροδο του χρόνου. Τα cookies αυτά μπορεί να μας παρέχονται από τρίτους παρόχους αναλυτικών εργαλείων αλλά χρησιμοποιούνται μόνο για τους σκοπούς που σχετίζονται με τις ιστοσελίδες μας.',
                                    ccb_switch : {
                                        value : 'STATISTICS_COOKIES',
                                        enabled : true,
                                        readonly: false
                                    },
                                    ccb_cookies_table: [
                                        {
                                            col1: '_ga',
                                            col2: 'Google Tag Manager',
                                            col3: '2 χρόνια',
                                            col4: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.' ,
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: '_ga_#',
                                            col2: 'Google Tag Manager',
                                            col3: '2 χρόνια',
                                            col4: 'Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit. ' ,
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: '_gat',
                                            col2: 'Google Tag Manager',
                                            col3: '1 ημέρα',
                                            col4: 'Used by Google Analytics to throttle request rate' ,
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: '_gid',
                                            col2: 'Google Tag Manager',
                                            col3: '1 ημέρα',
                                            col4: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.' ,
                                            col5: 'HTTP'
                                        }
                                    ]
                                },
                                {
                                    ccb_title : "Cookies στόχευσης/διαφήμισης",
                                    ccb_description: 'Αυτά τα cookies χρησιμοποιούνται για την παροχή περιεχομένου, που ταιριάζει περισσότερο σε εσάς και τα ενδιαφέροντά σας. Μπορεί να χρησιμοποιηθούν για την αποστολή στοχευμένης διαφήμισης/προσφορών, τον περιορισμό προβολών διαφήμισης ή την μέτρηση αποτελεσματικότητας μιας διαφημιστικής καμπάνιας. Μπορεί να χρησιμοποιηθούν αυτά τα cookies για να θυμόμαστε τους ιστότοπους που έχετε επισκεφθεί ώστε να καθορίσουμε ποια ηλεκτρονικά κανάλια μάρκετινγκ είναι πιο αποτελεσματικά και μας επιτρέπουν να επιβραβεύσουμε εξωτερικές ιστοσελίδες και συνεργάτες που σας προώθησαν σε εμάς.',
                                    ccb_switch : {
                                        value : 'MARKETING_COOKIES',
                                        enabled : true,
                                        readonly: false
                                    },
                                    ccb_cookies_table: [
                                        {
                                            col1: 'test_cookie',
                                            col2: 'Google',
                                            col3: '1 ημέρα',
                                            col4: 'Used to check if the user browser supports cookies.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'IDE',
                                            col2: 'Google',
                                            col3: '1 χρόνο',
                                            col4: 'Used by Google DoubleClick to register and report the website user actions after viewing or clicking one of the advertiser ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'VISITOR_INFO1_LIVE',
                                            col2: 'YouTube',
                                            col3: '179 ημέρες',
                                            col4: 'Tries to estimate the user bandwidth on pages with integrated YouTube videos.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'vuid',
                                            col2: 'Vimeo',
                                            col3: '2 χρόνια',
                                            col4: 'Registers a unique ID to keep statistics of what videos from Vimeo the user has seen.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'YSC',
                                            col2: 'YouTube',
                                            col3: 'Συνεδρία',
                                            col4: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'yt.innertube::nextId',
                                            col2: 'YouTube',
                                            col3: 'Συνεχόμενο',
                                            col4: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt.innertube::requests',
                                            col2: 'YouTube',
                                            col3: 'Συνεχόμενο',
                                            col4: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-cast-installed',
                                            col2: 'YouTube',
                                            col3: 'Συνεχόμενο',
                                            col4: 'Stores the user video player preferences using embedded YouTube video.' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-connected-devices',
                                            col2: 'YouTube',
                                            col3: 'Συνεχόμενο',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-device-id',
                                            col2: 'YouTube',
                                            col3: 'Συνεχόμενο',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-fast-check-period',
                                            col2: 'YouTube',
                                            col3: 'Συνεδρία',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-session-app',
                                            col2: 'YouTube',
                                            col3: 'Συνεδρία',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-session-name',
                                            col2: 'YouTube',
                                            col3: 'Συνεδρία',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        }
                                    ]
                                },
                                {
                                    ccb_title : "Cookies προτιμήσεων",
                                    ccb_description: 'Αυτά τα cookies χρησιμοποιούνται για να αποθηκεύονται δικές σας προτιμήσεις όσων αφορά την ιστοσελίδα μας και τις εφαρμογές της. Για παράδειγμα χρώματα, ρυθμίσεις προσβασιμότητας κτλ.',
                                    ccb_switch : {
                                        value : 'PREFERENCES_COOKIES',
                                        enabled : true,
                                        readonly: false
                                    },
                                    ccb_cookies_table: [
                                        {
                                            col1: 'accessibility',
                                            col2: 'LiveOn',
                                            col3: 'Συνεχόμενο',
                                            col4: 'Αποθήκευση προτιμήσεων χρήστη για στοιχεία προσβασιμότητας της σελίδας' ,
                                            col5: 'Local Storage'
                                        }
                                    ]
                                }
                                ,{
                                    ccb_title : "Περισσότερες πληροφορίες",
                                    ccb_description: 'Για περισσότερες πληροφορίες σχετικα με την χρήση των Cookies από την σελίδα μας μπορείτε να μεταβείτε στην σελίδα της <a href="'+response.data.privacy_policy_url+'">πολιτικής μας</a>.',
                                }
                            ]
                        }
                    },
                    {
                        lang : 'en',
                        modal : {
                            cc_title :  "We use cookies on this site",
                            cc_more_text :  "Learn more",
                            cc_accept_text : "Accept",
                            cc_description :  'Our company follows a strict policy to protect the privacy of visitors to our website. This section provides additional information on how we use “cookies” and other automated tools to collect specific information regarding visitors to our websites, such as number of visitors and most visited web pages, regardless of browser or device used. Collecting this information helps us learn how to better adapt our web pages and our advertising to visitor preferences.',
                        },
                        policy : {
                            ccp_title : "Cookie policy",
                            ccp_save_text : "Save",
                            ccb_table_headers : [
                                {col1: "Name" },
                                {col2: "Cookie by" },
                                {col3: "Expires" },
                                {col4: "Description" },
                                {col5: "Type" }
                            ],
                            ccp_blocks : [
                                {
                                    ccb_title : "What are cookies?",
                                    ccb_description: 'Cookies are small text files stored on your device whenever you use any browser to visit a website. For example, we can use cookies to learn whether you have visited our website in the past or whether you are a first-time user. They also help us to identify which features of our website interest you more. Cookies can improve your internet experience by storing your preferences as you visit a particular website.'
                                },{
                                    ccb_title : "Essential cookies",
                                    ccb_description: 'The essential cookies are critically important to the proper functioning of the website; they allow you to navigate and use its functions, including accessing secure areas, registration forms, favourites list, shopping basket and for security reasons. These cookies do not recognise your personal identity. Without these cookies, our website cannot function effectively.',
                                    ccb_cookies_table : [
                                        {
                                            col1: 'cc_cookie',
                                            col2: 'LiveOn',
                                            col3: '3 months (From acceptance)',
                                            col4: 'To let us know you accept cookies.',
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: response.data.session_cookie,
                                            col2: 'LiveOn',
                                            col3: 'Session',
                                            col4: 'So we can allow you to access areas of the LiveOn platform.',
                                            col5: 'HTTP'
                                        },{
                                            col1: 'XSRF-TOKEN',
                                            col2: 'LiveOn',
                                            col3: '8 hours',
                                            col4: 'So we can allow you to access areas of the LiveOn platform.',
                                            col5: 'HTTP'
                                        }
                                    ],
                                    ccb_switch : {
                                        value : 'NECESSARY_COOKIES',
                                        enabled : true,
                                        readonly: true
                                    }
                                },{
                                    ccb_title : "Performance and analytics cookies",
                                    ccb_description: 'These cookies collect information on the ways in which visitors use the website, which web pages they visit more often and whether they receive error messages from web pages. These cookies collect aggregated, anonymous information which does not identify visitors. They are used exclusively to improve the performance of a web page and allow us to collect information on how you use our website, including the content you choose when navigating through our web pages, to measure the effectiveness and consumer interaction with the web page and to improve our website over time. These cookies may be placed by third-party providers of analytics tools but are used only for purposes related to our website.',
                                    ccb_switch : {
                                        value : 'STATISTICS_COOKIES',
                                        enabled : true,
                                        readonly: false
                                    },
                                    ccb_cookies_table: [
                                        {
                                            col1: '_ga',
                                            col2: 'Google Tag Manager',
                                            col3: '2 years',
                                            col4: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.' ,
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: '_ga_#',
                                            col2: 'Google Tag Manager',
                                            col3: '2 years',
                                            col4: 'Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit. ' ,
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: '_gat',
                                            col2: 'Google Tag Manager',
                                            col3: '1 day',
                                            col4: 'Used by Google Analytics to throttle request rate' ,
                                            col5: 'HTTP'
                                        },
                                        {
                                            col1: '_gid',
                                            col2: 'Google Tag Manager',
                                            col3: '1 day',
                                            col4: 'Registers a unique ID that is used to generate statistical data on how the visitor uses the website.' ,
                                            col5: 'HTTP'
                                        }
                                    ]
                                },
                                {
                                    ccb_title : "Targeting/advertising cookies",
                                    ccb_description: 'These cookies are used to provide content that is tailored to you and your interests. They can be used to send targeted advertising or special offers, to limit advertising exposure or to measure the impact of an advertising campaign. These cookies can be used to help us remember the websites you have visited and to determine which electronic marketing channels are most effective. They allow us to reward external websites and partners who redirect you to us.',
                                    ccb_switch : {
                                        value : 'MARKETING_COOKIES',
                                        enabled : true,
                                        readonly: false
                                    },
                                    ccb_cookies_table: [
                                        {
                                            col1: 'test_cookie',
                                            col2: 'Google',
                                            col3: '1 day',
                                            col4: 'Used to check if the user browser supports cookies.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'IDE',
                                            col2: 'Google',
                                            col3: '1 year',
                                            col4: 'Used by Google DoubleClick to register and report the website user actions after viewing or clicking one of the advertiser ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'vuid',
                                            col2: 'Vimeo',
                                            col3: '2 years',
                                            col4: 'Registers a unique ID to keep statistics of what videos from Vimeo the user has seen.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'VISITOR_INFO1_LIVE',
                                            col2: 'YouTube',
                                            col3: '179 days',
                                            col4: 'Tries to estimate the user bandwidth on pages with integrated YouTube videos.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'YSC',
                                            col2: 'YouTube',
                                            col3: 'Session',
                                            col4: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.' ,
                                            col5: 'HTTP'
                                        },{
                                            col1: 'yt.innertube::nextId',
                                            col2: 'YouTube',
                                            col3: 'Persistent',
                                            col4: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt.innertube::requests',
                                            col2: 'YouTube',
                                            col3: 'Persistent',
                                            col4: 'Registers a unique ID to keep statistics of what videos from YouTube the user has seen.' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-cast-installed',
                                            col2: 'YouTube',
                                            col3: 'Persistent',
                                            col4: 'Stores the user video player preferences using embedded YouTube video.' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-connected-devices',
                                            col2: 'YouTube',
                                            col3: 'Persistent',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-device-id',
                                            col2: 'YouTube',
                                            col3: 'Persistent',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-fast-check-period',
                                            col2: 'YouTube',
                                            col3: 'Session',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-session-app',
                                            col2: 'YouTube',
                                            col3: 'Session',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        },{
                                            col1: 'yt-remote-session-name',
                                            col2: 'YouTube',
                                            col3: 'Session',
                                            col4: 'Stores the user video player preferences using embedded YouTube video' ,
                                            col5: 'HTML'
                                        }
                                    ]
                                },
                                {
                                    ccb_title : "Preference cookies",
                                    ccb_description: 'These cookies are used to store your own preferences regarding our website and its applications. Such preferences may include colours, access settings, etc.',
                                    ccb_switch : {
                                        value : 'PREFERENCES_COOKIES',
                                        enabled : true,
                                        readonly: false
                                    },
                                    ccb_cookies_table: [
                                        {
                                            col1: 'accessibility',
                                            col2: 'LiveOn',
                                            col3: 'Persistent',
                                            col4: 'Store user preferences on website access information' ,
                                            col5: 'Local Storage'
                                        }
                                    ]
                                }
                                ,{
                                    ccb_title : "More information",
                                    ccb_description: 'More information on how our website uses cookies is available on our <a href="'+response.data.privacy_policy_url+'">policy page</a>.',
                                }
                            ]
                        }
                    }
                ]
            };
            const cc = window.initCookieConsent();
            cc.run(cookiesObject);
            cc.show();

            setTimeout(() => {
                $(document).on('click',".ccp_section",function (){
                    $(this).toggleClass("opened");
                });
            }, 500);
        }
        // Call the function
        loadPost();
    }, []);

    return null;
}