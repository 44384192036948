import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import {useEffect, useState} from "react";
import * as constants from "./Constants";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import {resolve} from "chart.js/helpers";


export default function FileUploadComponentTranslatable(props) {


    const {t, i18n} = useTranslation();
    const [changeImage, setChangeImage] = useState(false);
    const [removeImage, setRemoveImage] = useState(false);
    const [preloadImage, setPreloadImage] = useState("");

    useEffect(() => {

        if(props.image && props.image.length>0 && props.image!="/"){
            // console.log("props.image",props.image)

            setPreloadImage(props.image);
        }else{
            setPreloadImage("/ethos_img/placeholder.jpg");
        }

    }, [props.image]);

    // const blobToImage = (blob) => {
    //     return new Promise(resolve => {
    //         const url = URL.createObjectURL(blob)
    //         let img = new Image()
    //         img.onload = () => {
    //             URL.revokeObjectURL(url)
    //             resolve(img)
    //         }
    //         img.src = url
    //     })
    // }
    //
    // const getImageForDimensions = async (file) => {
    //     if (file.type.match(constants.acceptedUploadMimeGroups.images)) {
    //         // var w = 0;
    //         // var h = 0;
    //         // var img = new Image();
    //         // img.onload = () => {
    //         //     w = img.width;
    //         //     h = img.height;
    //         // };
    //         // var blob = URL.createObjectURL(file);
    //         // img.src = blob;
    //         let img = await blobToImage(file);
    //         if (img != null && props.maxWidth && props.maxHeight && ( img.width == 0 || img.height == 0 || img.width > props.maxWidth || img.height > props.maxHeight ) ) {
    //             validationOutcome = {
    //                 code: "error-outside-dimensions",
    //                 message: `Larger than given dimensions`
    //             }
    //         }
    //         return img;
    //     }
    //     return null;
    // }
    //
    // let validationOutcome = null;
    // async function extraCustomValidation(event_data) {
    //     validationOutcome = null;
    //     let img = await getImageForDimensions(event_data.file);
    //     console.info('extraCustomValidation1',img, img.width, img.height);
    //     console.info('extraCustomValidation2',img != null, props.maxWidth, props.maxHeight);
    //     console.info('extraCustomValidation3', (img.width == 0), (img.height == 0), (img.width > props.maxWidth), (img.height > props.maxHeight));
    //     if (img != null && props.maxWidth && props.maxHeight && ( img.width == 0 || img.height == 0 || img.width > props.maxWidth || img.height > props.maxHeight ) ) {
    //         validationOutcome = {
    //             code: "error-outside-dimensions",
    //             message: `Larger than given dimensions`
    //         }
    //         return {
    //             code: "error-outside-dimensions",
    //             message: `Larger than given dimensions`
    //         };
    //     }
    //     else return null;
    // }

    const notify_delete = () => toast.success(name + ' File was deleted!', {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_wrong = (message=null) => toast.error(message??t('Something went wrong!'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_saved = (name) => toast.success(name + ' Succesfully saved!', {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const deleteImage = () => {
        window.axios.post(constants.LocalUrl + '/data/save_file', {...props.extra, type:"delete",lang:props.locale}).then(response => {
            setPreloadImage("/ethos_img/placeholder.jpg");

                props.reloadStage(true);
                setChangeImage(false)

        });
    }

    // specify upload params and url for your files
    const getUploadParams = ({meta}) => {
        return {
            url: '/data/save_file',
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
            fields: props.extra
        }
    }

    const processSuccessfulFile = (file, xhr, meta) => {
        if (props.getImage) {
            props.getImage(file)
        }
        if (props.callerCallback) {
            props.callerCallback(xhr);
        }


        props.reloadStage(true);
        setChangeImage(false);

        notify_saved(meta.name);
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({meta, remove, file, xhr}, status) => {
        // console.log("handleChangeStatus", meta, remove, file, xhr, status)

        if (props.notautoprocess === true) {
            if (status === 'preparing') {
                props.getImage(file);
                setChangeImage(true);
            }
            else if (status == 'error_file_size') {
                notify_wrong(t('upload_error_file_size_too_big'));
                remove();
            }
            else if (status == 'rejected_file_type') {
                notify_wrong(t('upload_error_file_type_not_accepted'));
                remove();
            }
            else if (status == 'error_validation') {
                notify_wrong(t('upload_error_larger_than_dimensions'));
                remove();
            }
        } else {

            if (status === 'headers_received') {
                // let response = JSON.parse(xhr.response);
                processSuccessfulFile(file, xhr, meta)

            } else if (status === 'aborted') {
                notify_wrong();
                remove();
            }
            else if (status === 'removed') {
                if(changeImage === true){
                    window.axios.post(constants.LocalUrl + '/data/save_file', {
                        action: props.extra.action,
                        type: "delete",
                        lang:props.locale,
                    }).then(response => {

                    });
                    notify_delete();
                    setPreloadImage("/ethos_img/placeholder.jpg");
                    setChangeImage(false);
                }
            }
            else if (status == 'error_file_size') {
                notify_wrong(t('upload_error_file_size_too_big'));
                remove();
            }
            else if (status == 'rejected_file_type') {
                notify_wrong(t('upload_error_file_type_not_accepted'));
                remove();
            }
            else if (status == 'error_validation') {
                notify_wrong(t('upload_error_larger_than_dimensions'));
                remove();
            }
        }

    }

    const Layout = ({status, input, previews, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {

        return (
            <div className="single_pic_dropzone">
                {changeImage && !props.reloadStage ? previews : <div className="dropzone_loaded_image">
                    {props.image && props.image.length > 0 && props.image != "/" && !preloadImage.endsWith('/placeholder.jpg') ? <a onClick={() => deleteImage()}><div className="dzu-previewStatusContainer before"><span
                        className="dzu-previewButton"
                        style={{backgroundImage:<CloseIcon/>}}><CloseIcon/></span></div></a> : null}
                    <img src={preloadImage} alt=""/></div>}


                <div {...dropzoneProps}>
                    <label className="fake_Dropzone_button">
                        {t('UPLOAD')}
                        {/*{files.length < maxFiles && input}*/}
                        {input}
                    </label>
                </div>

                {/*{files.length > 0 && submitButton}*/}
            </div>
        )
    }

    return (
        <Dropzone
            getUploadParams={props.notautoprocess === true ? "" : getUploadParams}
            onChangeStatus={handleChangeStatus}
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
            // onDrop={acceptedFiles => console.log(file)}
            // submitButton={submm}
            autoProcessQueue={props.notautoprocess === true ? false : true}
            previewsContainer={null}
            LayoutComponent={Layout}
            // maxFiles={1}
            multiple={false}
            canCancel={false}
            inputContent=""
            accept={props.accept}
            maxSizeBytes={props.maxSizeBytes}
            /*validate={(event_data) => {
                const response = extraCustomValidation(event_data);
                console.info('extraCustomValidation1 validationOutcome', validationOutcome);
                return validationOutcome;
            }}*/
            styles={{
                dropzone: {width: 400, height: 200},
                dropzoneActive: {borderColor: 'green'},
            }}
        />
    )


}