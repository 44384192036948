import React, {useEffect, forwardRef, useImperativeHandle} from 'react';
import {useTranslation} from "react-i18next";
import DrawerWindow from "./DrawerWindow";
import HelpValue from "./HelpValue";
import PanelBottomBar from "../../common/PanelBottomBar";

const HelpDrawer = forwardRef((props, ref) => {

    const {t, i18n} = useTranslation();
    const [drawerstate, setDrawerstate] = React.useState({
            open: false,
            id_help: props.id??null,
        }
    );

    useImperativeHandle(ref, () => ({
        toggleDrawer, toggleDrawerAndId
    }));

    const toggleDrawer = (open_state) => {
        setDrawerstate({...drawerstate, open: open_state});
    };

    const toggleDrawerAndId = (open_state, id_help) => {
        setDrawerstate({...drawerstate, open: open_state, id_help: id_help});
    };

    return (
        <div ref={ref}>
            <DrawerWindow open={drawerstate.open}>
                <div style={{width:'70vh'}}>
                    <HelpValue id={drawerstate.id_help} ></HelpValue>
                    <PanelBottomBar classes={"panel_utilities_element basicinfo"} style={{justifyContent:"flex-start"}} close={()=>toggleDrawer(false)}/>
                </div>
            </DrawerWindow>
        </div>
    )
});

HelpDrawer.displayName = "HelpDrawer";
export default HelpDrawer;
