import React, {useEffect, useState} from "react";
import axios from "axios";
import * as constants from "../../Panel/common/Constants";
import Grid from "@material-ui/core/Grid";
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import UserCard from "../../common/UserCard";
import Loader from "../../common/Loader"
import {useParams} from "react-router-dom";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                {children}
                </>
                // <Box sx={{ p: 3 }}>
                //     <Typography>{children}</Typography>
                // </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SpeakerProfile(){

    const [speaker, setSpeaker] = useState([]);
    const [value, setValue] = React.useState(speaker.cv && speaker.lectures?0:1);
    const {t, i18n} = useTranslation();
    let {user_id} = useParams();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

        axios.post(constants.LocalUrl + '/data/get_full_speaker_data',{id:user_id})
            .then(res => {
                setSpeaker(res.data);
            })


    },[user_id])

    if(Object.keys(speaker).length == 0){
        return <Loader />
    }

    return(
        <div style={{width:"100%",backgroundColor:"#fafafa",height:"100vh"}}>
        <div id="speaker_profile" style={{maxWidth:"1473px",width:"100%",paddingTop:"52px",paddingBottom:"35px",margin:"0 auto"}}>

            <div className="speaker_profile_body">
                <div className={speaker.cv && speaker.cv.length > 0 || speaker.lectures && speaker.lectures.length > 0?"speaker_profile_business_card":"speaker_profile_business_card full_layout"}>
                    <Grid item xs={12} className="video_participants">
                        <div className="card"
                             style={{borderRadius: '20px', marginBottom: '20px', paddingBottom: '17px'}}>
                                <h6 className="card-title" style={{
                                fontSize: '10px',
                                textAlign: 'center',
                                fontWeight: '700',
                                marginTop: '10px'
                            }}>{t('Business Card')}</h6>
                            <div className="participants_body">
                                <div className="speaker_profile_header" style={{marginBottom:"30px"}}>
                                    <div className="card_speaker">
                                        <div className="speaker_profile_content">
                                            <div className="speaker_img">
                                                {speaker.user_img?<img src={speaker.user_img} alt={speaker.name}/>:""}
                                            </div>
                                            <div className="speaker_referrence">
                                                <div className="speaker_referrence_content">
                                                    <span className="name">{speaker.name?speaker.name:""}</span>
                                                    <span className="job">{speaker.jobtitle?speaker.jobtitle:""} {speaker.company?"@ " + speaker.company:""}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    {speaker.company?<li>{t("Company")}: <span className="user_attribute">{speaker.company}</span></li>:""}
                                    {speaker.phone?<li>{t("Phone")}: <span className="user_attribute">{speaker.phone}</span></li>:""}
                                    {speaker.title?<li>{t("Title")}: <span className="user_attribute">{speaker.title}</span></li>:""}
                                    {speaker.job?<li>{t("Job title")}: <span className="user_attribute">{speaker.job}</span></li>:""}
                                    {speaker.country?<li>{t("Country")}: <span className="user_attribute">{speaker.country}</span></li>:""}
                                    {speaker.language?<li>{t("Language")}: <span className="user_attribute">{speaker.language}</span></li>:""}
                                    {speaker.timezone?<li>{t("Timezone")}: <span className="user_attribute">{speaker.timezone}</span></li>:""}
                                    {speaker.website?<li>{t("Website")}: <span className="user_attribute">{speaker.website}</span></li>:""}
                                </ul>
                            </div>
                        </div>
                    </Grid>
                </div>

                {speaker.cv && speaker.cv.length > 0 || speaker.lectures && speaker.lectures.length > 0?<div className="speaker_profile_business_card more">
                    <div className="card"
                         style={{borderRadius: '20px', marginBottom: '20px', paddingBottom: '17px', marginLeft:"18px"}}>
                        <Tabs value={value} onChange={handleChange}  >
                            {speaker.cv?<Tab value={0} label={t("CV")} {...a11yProps(0)} />:""}
                            {speaker.lectures?<Tab value={1} label={t("Speaking at")} {...a11yProps(1)} />:null}
                        </Tabs>
                        {speaker.cv?<TabPanel value={value} index={0}>
                            <div className="tab-pane active" role="tabpanel" id="tab-1" dangerouslySetInnerHTML={{__html: speaker.cv?speaker.cv:""}}/>
                        </TabPanel>:""}

                        {speaker.lectures?<TabPanel value={value} index={1}>
                            {speaker.lectures && speaker.lectures.map(lecture =>

                                <div className="tab-pane active ssssot" role="tabpanel" id="tab-1">
                                    <div className="d-lg-flex" style={{display: 'flex'}}>
                                        <p style={{fontSize: '20px', fontWeight: 400}}><WatchLaterIcon />{lecture.starts_at_time_text} - {lecture.ends_at_time_text}</p>
                                        <p style={{margin: '0 12px'}}>|</p>
                                        <p style={{fontSize: '22px'}}><strong>{lecture.title[i18n.language]}</strong></p>
                                    </div>
                                    <hr style={{height: '1px', margin: 0, color: '#b3b3b3'}} />

                                    <div>
                                        <h1 style={{
                                            margin: 0,
                                            lineHeight: '29px',
                                            fontWeight: 400,
                                            padding: '12px 0',
                                            fontSize: '20px',
                                            color: '#000'
                                        }} dangerouslySetInnerHTML={{__html: lecture.subtitle[i18n.language]}}/>
                                        <hr style={{height: '1px', margin: 0, color: '#b3b3b3'}}/>

                                        <div className="session_content" style={{display:"flex",justifyContent:"space-between"}}>
                                            <div className="session_content_inner" style={{width:"50%"}}>
                                                {/*{console.log("lecture.syntonistes",lecture.syntonistes)}*/}
                                                {lecture.syntonistes && lecture.syntonistes != 0?<div style={{padding: '18px 0 0 30px'}}>
                                                    <h1 style={{fontSize: '20px', fontWeight: 700}}>{t('COORDINATOR')}</h1>
                                                    {lecture.syntonistes && lecture.syntonistes.map(coordinator => (
                                                        <UserCard user={coordinator} />
                                                    ))}
                                                </div>:""}


                                                {lecture.speakerz?<div style={{padding: '18px 0 0 30px'}}>
                                                    <h1 style={{fontSize: '20px', fontWeight: 700}}>{t('SPEAKERS')}</h1>
                                                    {lecture.speakerz && lecture.speakerz.map(speaker => (
                                                        <UserCard user={speaker} />
                                                    ))}
                                                </div>:""}
                                            </div>
                                            <div>
                                            </div>
                                        </div>


                                    </div>
                                </div>)}
                        </TabPanel>:null}

                    </div>
                </div>:null}

            </div>
        </div>
        </div>
    )

}