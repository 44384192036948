import React, {useEffect, useState} from 'react';
import * as constants from "./Panel/common/Constants";
import ListFiles from "./Panel/tabs/Sponsors/components/files/ListFiles";


export default function test(){

    const [sponsorid, setSponsorid] = useState(null);
    const [sponsor, setSponsor] = useState([]);
    const [executives, setExecutives] = useState([]);
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [showSponsorFiles, setShowSponsorFiles] = useState({state:false,id:''});
    const [sponsorFiles, setSponsorFiles] = useState([]);
    const [filename, setFilename] = useState('');

    const showSponsorFilesMenu = (state,id) => () => {
        console.log(sponsorFiles,state,id)
        setShowSponsorFiles({state:state,id:id});
    };

    useEffect(()=>{
        const response = axios.post(constants.LocalUrl + '/data/sponsor_full_data', {id: 1,type:'panel'}).then(response => {
            console.log('response full data', response.data.files);
            // After fetching data stored it in posts state.
            setSponsor(response.data);
            setSponsorFiles(response.data.files);

            // Closed the loading page
            setLoading(false);
        });
    },[])

    if(Object.keys(sponsorFiles).length === 0){
        return "...";
    }

    return (
        <>
            <ListFiles  data={sponsorFiles} OnClick={showSponsorFilesMenu}/>
        </>
    )

}