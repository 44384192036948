import {createContext, useEffect, useState} from "react";
import {toast} from "react-toastify";

const ToastContext = createContext();

export function ToastProvider({children}){



    const notify = (text) => toast.success(text, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    notify();
    // useEffect(() => {
    //
    //
    // }// Call the function
    // }, []);

    // const [activatedSteps,setActivatedSteps] = useState([1]);
    // console.log(stepButtonState)
    // const handleStepButtonState = (status = false) => {
    //     setStepButtonState((prevState) => [...prevState,true])
    // }

    return(
        <ToastContext.Provider value={{notify}}>
            {children}
        </ToastContext.Provider>

    )
}






export default ToastContext;