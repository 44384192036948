import React, {useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
// import NewSponsor from "./NewSponsor";



export default function DrawerWindow(props)  {

    const {t, i18n} = useTranslation();
    const [drawerstate, setDrawerstate] = useState({
            open: false,
            open_cat: false,
            id: null,
            id_cat: null,
        }
    );
    const useStyles = makeStyles({
        paper: {
            // width: '800px',
            padding:'7px 15px'
        },
    });

    const classes = useStyles();

    let direction = props.direction?props.direction:'right';

    const list = (anchor) => (
        <div
            // className={clsx(classes.list, {
            //     [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            // })}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            {props.children}
        </div>
    );
// console.log("////");
// console.log(open);

    return (

        props.type =="contained"?<Drawer
                anchor={direction}
                open={props.open}
                // onClose={toggleDrawer('right', false)}
                // onOpen={toggleDrawer('right', true)}
                persistent='permanent'
                classes={classes}
                PaperProps={{ style: { position: 'absolute',height:"100%" } }}
                BackdropProps={{ style: { position: 'absolute',height:"100%" } }}
                ModalProps={{
                    container: document.getElementById('drawer-container'),
                    style: { position: 'absolute', height:"100%" }
                }}
                variant="persistent"
            >

                {list('right')}
            </Drawer>:<Drawer
            anchor={direction}
            open={props.open}
            // onClose={toggleDrawer('right', false)}
            // onOpen={toggleDrawer('right', true)}
            persistent='permanent'
            classes={classes}
        >

            {list('right')}
        </Drawer>

    );

}

