import React from "react";
import Speakers from "./content/Speakers";
import Sponsors from "./content/Sponsors";
import HomepageHeading from "./content/HomepageHeading";
import About from "./content/About";
import DateTimer from "./content/DateTimer";
import '../../App.css';
import {createMedia} from "@artsy/fresnel";


const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
                mobile: 0,
                tablet: 768,
                computer: 1024,
        },
})


export default function HomepageLayout() {



        return (
            // <MediaContextProvider>
                <div>
                {/*<Media at='computer'>*/}
                        <HomepageHeading/>
                        <About />
                        <Speakers />
                        <Sponsors />
                        <DateTimer />
                {/*</Media>*/}
                {/*<div>*/}
                {/*<Media greaterThan='mobile'>*/}
                {/*        /!*<HomepageHeading/>*!/*/}
                {/*        /!*<About />*!/*/}
                {/*        /!*<Speakers />*!/*/}
                {/*        /!*<Sponsors />*!/*/}
                {/*        /!*<DateTimer />*!/*/}
                {/*</Media>*/}
                {/*</div>*/}
                </div>
            // </MediaContextProvider>

        )

}